import React, { useState } from 'react';
import Section from '../../../common/Section';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { PostContact } from '../../../../services/ContactService';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jcEdy from '../../../../assets/img/tredunion/contacts/jcEdy.png';
import anneK from '../../../../assets/img/tredunion/contacts/anneK.png';
import isabelleH from '../../../../assets/img/tredunion/contacts/isabelleH.png';
import julieB from '../../../../assets/img/tredunion/contacts/JulieB.png';
import franckS from '../../../../assets/img/tredunion/contacts/franckS.png';
import mykytaB from '../../../../assets/img/tredunion/contacts/mykytaB.png';
import olivierP from '../../../../assets/img/tredunion/contacts/olivierPe.png';
import maxContact from '../../../../assets/img/tredunion/contacts/max_contact.jpeg';

const Contact = () => {
  const [formData, setFormData] = useState({
    subject: "Demande d'information",
    lastname: '',
    firstname: '',
    occupation: '',
    email: '',
    phone: '',
    message: '',
    legal: 0,
    'g-recaptcha-response': false
  });
  const contacts = [
    {
      image: jcEdy,
      name: 'Jean-Christophe EDY',
      occupation: 'Animateur',
      email: 'info@tredunion.fr'
    },
    {
      image: anneK,
      name: 'Anne DANNER',
      occupation: 'Coordination',
      email: 'info@tredunion.fr'
    },
    {
      image: julieB,
      name: 'Julie BLETTNER',
      occupation: 'Evénementiel',
      email: 'info@tredunion.fr'
    },
    {
      image: franckS,
      name: 'Franck SENG',
      occupation: 'Comptabilité',
      email: 'francks@tredunion.fr'
    },
    {
      image: mykytaB,
      name: 'Mykyta BOKATOV',
      occupation: 'Assistant achat',
      email: 'info@tredunion.fr'
    },
    {
      image: olivierP,
      name: 'Olivier PERRIN',
      occupation: 'Directeur de réseau',
      email: 'op@tredunion.fr'
    }
  ];
  const [errorForm, setErrorForm] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [contactSent, setContactSent] = useState(false);
  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
      try {
        setButtonIsLoading(true);
        const token = await executeRecaptcha('check_contact_form_tredunion');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await PostContact(formDataSubmit);
        if (response.validator) {
          setErrorForm(response.validator);
          document.getElementById('contact').scrollIntoView();
        }
        setButtonIsLoading(false);
        if (response.success === true) {
          setContactSent(true);
        } else if (!response.validator) {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha
          onVerify={() => {
            return;
          }}
        />
        <Button
          disabled={buttonIsLoading}
          variant="primary"
          onClick={handleSubmit}
        >
          Envoyer
        </Button>
      </div>
    );
  };

  const handleFieldChange = e => {
    if (e.target.name === 'legal') {
      setFormData({
        ...formData,
        [e.target.name]: formData.legal === 0 ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };
  return (
    <>
      <Section bg="light" className="d-flex p-1 mt-5 mb-5" id="contact">
        <h2>CONTACT</h2>
        <Row className="mt-5">
          <Col lg={8} className="company-col-border pe-lg-5">
            {contactSent ? (
              <IconAlert variant="success">
                <p className="mb-0">
                  Merci, votre demande a bien été transmise à Tred Union.
                </p>
              </IconAlert>
            ) : (
              <>
                {errorForm && <FormAlert variant="danger" data={errorForm} />}
                <Form>
                  <Row className="mb-3 g-3">
                    <Form.Group as={Col} controlId="formGridSubject">
                      <Form.Label>Objet de votre demande *</Form.Label>
                      <Form.Select
                        aria-label="Statut"
                        value={formData.subject}
                        onChange={handleFieldChange}
                        name="subject"
                        required
                      >
                        <option value="false" disabled="disabled">
                          Choisissez un objet dans le menu déroulant
                        </option>
                        <option value="Demande d'information">
                          Demande d'information
                        </option>
                        <option value="Demande de partenariat">
                          Demande de partenariat
                        </option>
                        <option value="Demande d'adhésion">
                          Demande d'adhésion
                        </option>
                        <option value="Candidature">Candidature</option>
                        <option value="Suppression de mes informations personnelles">
                          Suppression de mes informations personnelles
                        </option>
                        <option value="Autre">Autre</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 g-3">
                    <Form.Group as={Col} controlId="formGridLastname">
                      <Form.Label>Nom *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre nom"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridFirstname">
                      <Form.Label>Prénom *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre prénom"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 g-3">
                    <Form.Group as={Col} controlId="formGridOccupation">
                      <Form.Label>Votre fonction</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre fonction"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 g-3">
                    <Form.Group
                      as={Col}
                      style={{ zIndex: 9 }}
                      controlId="formGridEmail"
                    >
                      <Form.Label>Adresse email *</Form.Label>
                      <Form.Control
                        type="mail"
                        placeholder="Votre adresse email"
                        name="email"
                        value={formData.email}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPhone">
                      <Form.Label>Numéro de téléphone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre numéro de téléphone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 g-3">
                    <Form.Group as={Col} controlId="formGridMessage">
                      <Form.Label>Message *</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Écrivez votre message ici"
                        value={formData.message}
                        onChange={handleFieldChange}
                        rows={3}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-1 g-3">
                    <Form.Group as={Col} className="mb-3" id="formGridlegal">
                      <label className="mb-0 cursor-pointer">
                        <input
                          type="checkbox"
                          id="legal"
                          name="legal"
                          value={1}
                          style={{
                            position: 'relative',
                            marginRight: '4px',
                            top: '1px'
                          }}
                          className="cursor-pointer"
                          checked={parseInt(formData.legal) === 1}
                          onChange={handleFieldChange}
                        />
                        Je reconnais avoir pris connaissance des mentions
                        légales et de l'usage des données personnelles
                        collectées.{' '}
                        <a
                          href="/politique-de-confidentialite"
                          target="_blank"
                          rel="noreferrer"
                        >
                          En savoir plus
                        </a>
                      </label>
                    </Form.Group>
                  </Row>
                  <GoogleReCaptchaProvider
                    reCaptchaKey="6LePlFwgAAAAAIga3vbKCRndalE_-0ki3eFiw-n3"
                    language="fr-FR"
                  >
                    <ReComponent />
                  </GoogleReCaptchaProvider>
                </Form>
              </>
            )}
          </Col>
          <Col lg={4} className="ps-lg-4 mt-4 mt-lg-0">
            <p className="mb-4">
              <b>Contacts TRED UNION</b>
            </p>
            {contacts.map((contact, index) => (
              <Row key={index} className="align-items-center mb-2 team-colmar">
                <Col xs={4}>
                  <img src={contact.image} className="rounded-circle  mw-100" />
                </Col>
                <Col xs={8}>
                  <p className="mb-0">
                    <b style={{ fontSize: 'small' }}>{contact.name}</b>
                  </p>
                  <p className="smaller-p mb-0">{contact.occupation}</p>
                  <p className="smaller-p mb-0">
                    <FontAwesomeIcon className="text-red" icon={'envelope'} />{' '}
                    <a
                      href={`mailto:${contact.email}?subject=A l'attention de ${contact.name}`}
                    >
                      <b>Contacter par email</b>
                    </a>
                  </p>
                </Col>
              </Row>
            ))}
            <p className="mt-4 mb-2 redHeading">Tred Union</p>
            <p className="smaller-p mb-0">
              4 rue de Lugano - 68180 Horbourg-Wihr
            </p>
            <p className="smaller-p mb-0">Tél. : 03 89 21 09 82</p>
            <p className="smaller-p mb-0">
              <a href="/mentions-legales">Mentions légales</a>
            </p>
            <p className="smaller-p mb-0">
              <a href="/politique-de-confidentialite">
                Politique de confidentialité
              </a>
            </p>
            <div id="max_contact" className="d-none d-lg-block">
              <img src={maxContact} />
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Contact;
