import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Row,
  Col,
  Alert,
  Button,
  Offcanvas,
  Table,
  Form,
  Spinner,
  Tabs,
  Tab
} from 'react-bootstrap';
import { ShowEvent } from '../../../../../services/Event/EventService';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Moment from 'moment/moment';
import moment from 'moment';
import SoftBadge from '../../../../common/SoftBadge';
import Vimeo from '@u-wave/react-vimeo';
import LineAnswers from '../surveys/charts/LineAnswers';
import RoundAnswers from '../surveys/charts/RoundAnswers';
import RecipientsTableEvent from '../surveys/tables/RecipientsTableEvent';
import SoftBadgeWithStyle from '../../../commons/SoftBadgeWithStyle';
import { Link, useSearchParams } from 'react-router-dom';
import ReduceAnswers from '../surveys/charts/reduceAnswers/reduceAnswers';
import ReviewAnswers from '../surveys/charts/reviewAnswers';
import TextAnswers from '../surveys/charts/textAnswers';
import ValidatePoints from './ValidatePoints';
import SendNotification from '../surveys/notification/SendNotification';
import EmailLogsTable from '../surveys/tables/EmailLogsTable';
import Background from '../../../../common/Background';
import corner1 from '../../../../../assets/img/illustrations/corner-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventShow = () => {
  const [event, setEvent] = useState({});
  const [trigger, setTrigger] = useState(Date.now());
  const [recipients, setRecipients] = useState([]);
  const [showRecipients, setShowRecipients] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchEvent = async () => {
      const responseObjects = await ShowEvent(id);
      if (responseObjects.success === true) {
        setEvent(responseObjects.data);
        let tmpRecipients = [];
        if (responseObjects.data.internal_recipients) {
          responseObjects.data.internal_recipients.map(recipient =>
            tmpRecipients.push({
              ...recipient,
              type: 'internal'
            })
          );
        }
        if (responseObjects.data.external_recipients) {
          responseObjects.data.external_recipients.map(recipient =>
            tmpRecipients.push({
              ...recipient,
              type: 'external'
            })
          );
        }
        setRecipients(tmpRecipients);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchEvent();
    setTimeout(function () {
      if (document.getElementById(window.location.hash.replace('#', ''))) {
        document
          .getElementById(window.location.hash.replace('#', ''))
          .scrollIntoView();
      }
    }, 900);
  }, [trigger]);

  const isColorLight = hex => {
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {isLoading === true ? (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <>
          <Card>
            {event.banner_picture_url && (
              <div
                style={{
                  backgroundImage: `url(${event.banner_picture_url})`,
                  display: 'block',
                  height: '250px',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              ></div>
            )}
            <Card.Body className="overflow-hidden px-lg-3">
              <Row className="justify-content-between align-items-center">
                <Col lg={12}></Col>
                <Col lg={6}>
                  <h3 className="text-primary admin-title mb-0">
                    Événement - {event.title}
                  </h3>
                  {event.category && (
                    <SoftBadgeWithStyle
                      style={{
                        backgroundColor: event.category.color,
                        color: isColorLight(event.category.color)
                          ? '#000000'
                          : '#FFFFFF'
                      }}
                      className="mt-2"
                    >
                      {event.category.name}
                    </SoftBadgeWithStyle>
                  )}
                </Col>
                <Col lg={6} className="text-end">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    onClick={() => {
                      if (searchParams.get('back')) {
                        navigate(searchParams.get('back'));
                      } else {
                        navigate('/administration/calendrier');
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      style={{ marginBottom: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                    Retour
                  </Button>
                  <Button
                    variant="primary"
                    className="admin-primary"
                    size="sm"
                    onClick={() => {
                      navigate(
                        `/administration/evenements/${event.id}/modifier?back=/administration/evenements/${event.id}`
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path
                        d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Modifier l'événement
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="mt-3">
            {Moment().diff(Moment(event.start_date), 'days') >= 0 &&
              !event.is_points_managed && (
                <ValidatePoints
                  event={event}
                  recipents={recipients}
                  setParentTrigger={setTrigger}
                />
              )}
            {Moment().diff(Moment(event.start_date), 'days') >= 0 &&
              !event.satisfaction_survey_id && (
                <Col xs={6} className="pb-3">
                  <Card className={`overflow-hidden`}>
                    <Background image={corner1} className="p-card bg-card" />
                    <Card.Body className="position-relative">
                      <h5
                        className={
                          event.is_points_managed === 0 ? 'text-warning' : ''
                        }
                        style={{ fontFamily: 'Open Sans' }}
                      >
                        <FontAwesomeIcon
                          icon="exclamation-circle"
                          className="me-2"
                          style={{ color: '#F5803E' }}
                        />
                        À faire - création du formulaire de satisfaction
                      </h5>
                      <a
                        href={`/administration/questionnaires/ajouter?name=Questionnaire%20de%20satisfaction%20-%20${
                          event.title
                        }&back=/administration/evenements/${
                          event.id
                        }&internal_recipients=${event.internal_recipients
                          .filter(
                            r =>
                              r.pivot?.did_not_come_to_event === 0 &&
                              r.survey_questions?.length > 0 &&
                              r.survey_questions?.find(
                                q => q.is_participation_form_main_question === 1
                              )?.pivot?.value === 'Oui'
                          )
                          .map(
                            r => r.id
                          )}&external_recipients=${event.external_recipients
                          .filter(
                            r =>
                              r.pivot?.did_not_come_to_event === 0 &&
                              r.survey_questions?.length > 0 &&
                              r.survey_questions?.find(
                                q => q.is_participation_form_main_question === 1
                              )?.pivot?.value === 'Oui'
                          )
                          .map(r => r.id)}&event_id=${event.id}`}
                        className="fs--1 text-warning mt-lg-3 ps-0 pt-2 d-block"
                      >
                        Créer le formulaire de satisfaction
                        <FontAwesomeIcon
                          icon="chevron-right"
                          className="ms-1 fs--2"
                        />
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            <Col
              xs={
                event.is_points_managed === 0 &&
                Moment().diff(Moment(event.start_date), 'days') >= 1 &&
                event.internal_recipients &&
                event.internal_recipients.filter(r => !r.partner_id).length > 0
                  ? 6
                  : 12
              }
            >
              <Card>
                <Card.Body>
                  <p className="smaller-p mb-0">
                    <b>Date :</b>{' '}
                    {event.start_is_all_day
                      ? `du
            ${Moment(event.start_date).local().format('DD/MM/YYYY')} ${moment(
                          event.start_time,
                          'HH:mm:ss'
                        ).format('HH:mm')} au
            ${Moment(event.end_date).local().format('DD/MM/YYYY')} ${moment(
                          event.end_time,
                          'HH:mm:ss'
                        ).format('HH:mm')}`
                      : `le ${Moment(event.start_date)
                          .local()
                          .format('DD/MM/YYYY')} de ${moment(
                          event.start_time,
                          'HH:mm:ss'
                        ).format('HH:mm')} à ${moment(
                          event.end_time,
                          'HH:mm:ss'
                        ).format('HH:mm')}`}
                  </p>
                  {event.description ? (
                    <p className="smaller-p mt-2 mb-0">
                      <b>Description :</b>{' '}
                      <span
                        className="smaller-p mb-0"
                        dangerouslySetInnerHTML={{
                          __html: event.description.replace(/\n/g, '<br />')
                        }}
                      ></span>
                    </p>
                  ) : (
                    ''
                  )}
                  {event.creator?.id && (
                    <p className="smaller-p mt-2 mb-0">
                      <b>Créateur :</b> {event.creator?.firstname}{' '}
                      {event.creator?.lastname}
                    </p>
                  )}
                  <p className="smaller-p mt-2 mb-0">
                    <b>Date de création :</b>{' '}
                    {Moment(event.created_at)
                      .local()
                      .format('DD/MM/YYYY HH:mm')}
                  </p>
                  <p className="smaller-p mt-2 mb-0">
                    <b>Date de dernière modification :</b>{' '}
                    {Moment(event.updated_at)
                      .local()
                      .format('DD/MM/YYYY HH:mm')}
                  </p>
                  {event.attachments?.length > 0 && (
                    <p className="smaller-p mt-2 mb-0">
                      <b>Pièces jointes :</b>{' '}
                      {event.attachments.map((attachment, index) => (
                        <span key={index}>
                          <a
                            href={attachment.file_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            pièce jointe {index + 1}
                          </a>
                          {event.attachments.length - 1 === index ? '' : ', '}
                        </span>
                      ))}
                    </p>
                  )}
                  {event.satisfaction_survey_id && (
                    <p className="smaller-p mt-2 mb-0">
                      <b>Questionnaire de satisfaction :</b>{' '}
                      <Link
                        to={`/administration/questionnaires/${event.satisfaction_survey_id}?back=/administration/evenements/${event.id}`}
                      >
                        voir
                      </Link>
                    </p>
                  )}
                  {event.max_number_participants && (
                    <p className="smaller-p mt-2 mb-0">
                      <b>
                        Nombre maximum d'inscriptions via le formulaire de
                        participation :
                      </b>{' '}
                      {event.max_number_participants}
                    </p>
                  )}
                </Card.Body>
              </Card>
              {recipients.length > 0 && (
                <Alert className="mt-3 mb-0" variant="primary">
                  <p className="smaller-p mb-1">
                    <b>Destinataires et emails automatiques :</b>
                  </p>
                  <p className="smaller-p mb-0">
                    <a
                      className="cursor-pointer"
                      onClick={() => setShowRecipients(true)}
                    >
                      {recipients.length} personne{recipients.length > 1 && 's'}
                    </a>{' '}
                    {recipients.length > 1 ? 'ont' : 'a'} été invitée
                    {recipients.length > 1 && 's'} à cet événement
                    {event.participation_form ? (
                      <>
                        {' '}
                        et {recipients.length > 1 ? 'doivent' : 'doit'} répondre
                        à un{' '}
                        <a
                          className="cursor-pointer"
                          onClick={() => setShowSurvey(true)}
                        >
                          formulaire de participation
                        </a>{' '}
                        avant le{' '}
                        {event.participation_form &&
                        event.participation_form.expires_on
                          ? Moment(event.participation_form.expires_on)
                              .local()
                              .format('DD/MM/YYYY')
                          : event.start_date
                          ? Moment(event.start_date)
                              .local()
                              .format('DD/MM/YYYY')
                          : ''}
                        .
                      </>
                    ) : (
                      '.'
                    )}
                  </p>
                  {event.participation_form && (
                    <>
                      <p className="smaller-p mb-0 mt-3">
                        <b>Date d'envoi du formulaire : </b>
                        {event.participation_form.starts_on
                          ? moment(event.participation_form.starts_on).format(
                              'DD/MM/YYYY'
                            )
                          : moment(event.participation_form.created_at).format(
                              'DD/MM/YYYY'
                            )}
                      </p>
                      {event.participation_form.survey_dunning_type_id ? (
                        <>
                          <p className="smaller-p mb-0 mt-3">
                            <b>Relances : </b> activées - envoi à partir du{' '}
                            {moment(
                              event.participation_form.dunning_starts_on
                            ).format('DD/MM/YYYY')}{' '}
                            - {event.participation_form.dunning_type?.title}
                          </p>
                        </>
                      ) : (
                        <p className="smaller-p mb-0 mt-3">
                          <b>Relances :</b> désactivées
                        </p>
                      )}
                      <p className="smaller-p mb-0 mt-3">
                        <b>Expiration du formulaire :</b>{' '}
                        {moment(event.participation_form.expires_on).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </>
                  )}
                  <SendNotification event={event} />
                </Alert>
              )}
              {event.surveys && event.surveys.length > 0 && (
                <Alert className="mt-3 mb-0" variant="primary">
                  <p className="smaller-p mb-1">
                    <b>
                      {event.surveys.length > 1
                        ? 'Plusieurs questionnaires sont liés'
                        : 'Un questionnaire est lié'}{' '}
                      à cet événement :
                    </b>
                  </p>
                  <ul className="mb-0">
                    {event.surveys.map((survey, index) => (
                      <li key={index}>
                        <a
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/administration/questionnaires/${survey.id}?back=/administration/evenements/${event.id}`
                            )
                          }
                        >
                          {survey.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Alert>
              )}
            </Col>
            {/*{event.location_label && (
              <Col
                md={event.link ? 6 : 12}
                className=" mt-3"
                style={{ minHeight: 200 }}
              >
                <GoogleMap
                  center={mapCenter}
                  zoom={12}
                  mapContainerStyle={containerStyle}
                  options={mapOptions}
                >
                  <Marker position={position} />
                </GoogleMap>
              </Col>
            )}*/}
            {event.link && (
              <Col md={event.location_label ? 6 : 12} className="mt-3">
                <div className="card" style={{ height: '100%' }}>
                  <div className="card-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="40"
                      height="40"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M17 9.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4.2zm0 3.159l4 2.8V8.84l-4 2.8v.718zM3 6v12h12V6H3zm2 2h2v2H5V8z"
                        fill="#e52f12"
                      />
                    </svg>
                    <h5
                      className="card-title mt-2 d-block"
                      style={{ color: '#4b4a4d', fontFamily: 'Open Sans' }}
                    >
                      Événement en ligne
                    </h5>
                    <Form.Control
                      disabled={true}
                      value={event.link}
                      size="sm"
                      type="text"
                      className="px-1"
                    />
                    <small>
                      <a
                        className="cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(event.link);
                          toast('Lien copié');
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="13"
                          height="13"
                          style={{
                            position: 'relative',
                            bottom: '1px',
                            marginRight: '2px'
                          }}
                        >
                          <path
                            d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z"
                            fill="rgba(44,123,229,1)"
                          />
                        </svg>
                        Copier dans le presse-papier
                      </a>
                    </small>
                    {event.link_code && (
                      <small>
                        <br />
                        Code : {event.link_code}
                      </small>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <div className="d-block mt-4"></div>
          <Tabs
            defaultActiveKey={event.participation_form ? 'recipients' : 'logs'}
            id="uncontrolled-tab-example"
          >
            {event.participation_form && (
              <Tab
                eventKey="recipients"
                title="Réponses du formulaire"
                className="p-0"
              >
                <RecipientsTableEvent
                  recipients={recipients}
                  survey={event.participation_form}
                  event={event}
                  setParentTrigger={setTrigger}
                />
              </Tab>
            )}
            {event.participation_form?.link_recipients?.find(
              r => r.from_recipients === 1
            ) && (
              <Tab eventKey="companies" title="Entreprises" className="p-0">
                <Row>
                  <Col xs={12} className="mt-3 p-3 px-3">
                    <table className="table table-striped table-hover table-stats-tracks">
                      <thead>
                        <th className="ps-0">
                          <b className="p-0">Entreprise</b>
                        </th>
                        <th>
                          <b className="p-0">Nombre de répondants</b>
                        </th>
                      </thead>
                      <tbody>
                        {event.participation_form?.link_recipients
                          .filter(l => l.from_recipients === 1)
                          ?.sort((a, b) =>
                            a.default_company?.localeCompare(b.default_company)
                          )
                          .map((company, index) => (
                            <tr key={index}>
                              <td>{company.default_company}</td>
                              <td
                                className={
                                  recipients.filter(r => {
                                    if (
                                      r.links?.find(l => l.id === company.id)
                                    ) {
                                      return r;
                                    }
                                  })?.length === 0
                                    ? 'text-danger'
                                    : ''
                                }
                              >
                                {
                                  recipients.filter(r => {
                                    if (
                                      r.links?.find(l => l.id === company.id)
                                    ) {
                                      return r;
                                    }
                                  })?.length
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Tab>
            )}
            {event.email_logs.length > 0 && (
              <Tab
                eventKey="logs"
                title="Historique des emails"
                className="p-0"
              >
                <Row>
                  <Col xs={12} className="pt-3" id="email-logs">
                    <EmailLogsTable object={event} recipients={recipients} />
                  </Col>
                </Row>
              </Tab>
            )}
            {event.participation_form && (
              <Tab eventKey="statistics" title="Statistiques" className="p-0">
                <Row>
                  <Col xs={8} className="mt-3">
                    <LineAnswers data={recipients} />
                  </Col>
                  <Col xs={4} className="ps-0 mt-3">
                    <RoundAnswers
                      data={recipients}
                      survey={event.participation_form}
                    />
                  </Col>
                  <Col xs={12}>
                    <Row>
                      {event.participation_form.questions.map(
                        (question, index) => (
                          <Fragment key={index}>
                            {question.type &&
                              ['checkbox', 'radio'].includes(
                                question.type.slug
                              ) && (
                                <ReduceAnswers
                                  question={question}
                                  recipients={recipients}
                                  survey={event.participation_form}
                                />
                              )}
                            {question.type &&
                              question.type.slug === 'review' && (
                                <ReviewAnswers
                                  question={question}
                                  recipients={recipients}
                                />
                              )}
                            {question.type &&
                              (question.type.slug === 'text' ||
                                question.type.slug === 'number') && (
                                <TextAnswers
                                  question={question}
                                  recipients={recipients}
                                />
                              )}
                          </Fragment>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </Tab>
            )}
          </Tabs>

          {recipients.length > 0 && (
            <Offcanvas
              show={showRecipients}
              onHide={() => {
                setShowRecipients(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Destinataires</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                <Table responsive className="table-stats-tracks">
                  <tbody>
                    {recipients.map((recipient, index) => (
                      <tr key={index}>
                        <td>
                          {recipient.login ? (
                            <>
                              {recipient.partner && recipient.partner.id ? (
                                <>
                                  {recipient.email}
                                  <SoftBadge bg="primary" className="ms-1">
                                    {recipient.partner.name}
                                  </SoftBadge>
                                </>
                              ) : (
                                <>
                                  {recipient.lastname} {recipient.firstname}
                                  {recipient.main_company &&
                                    recipient.main_company.name && (
                                      <SoftBadge bg="primary" className="ms-1">
                                        {recipient.main_company.name}
                                      </SoftBadge>
                                    )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {recipient.company_name &&
                                `${recipient.company_name} - `}
                              {(recipient.lastname || recipient.firstname) &&
                                `${recipient.lastname} ${recipient.firstname} - `}
                              {recipient.email}{' '}
                              <SoftBadge bg="info" className="ms-1">
                                Externe
                              </SoftBadge>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  variant="primary"
                  className="admin-primary"
                  size="sm"
                  onClick={() => {
                    navigate(
                      `/administration/evenements/${event.id}/modifier?back=/administration/evenements/${event.id}`
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Modifier
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="ms-2"
                  onClick={() => {
                    setShowRecipients(false);
                  }}
                >
                  Fermer
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          )}
          {event && event.participation_form && (
            <Offcanvas
              show={showSurvey}
              onHide={() => {
                setShowSurvey(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Formulaire de participation</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                {event.participation_form.description && (
                  <>
                    <p className="smaller-p mb-0">
                      <b>Description</b>
                    </p>
                    <p
                      className="smaller-p"
                      dangerouslySetInnerHTML={{
                        __html: event.participation_form.description
                      }}
                    />
                  </>
                )}
                {event.participation_form.vimeo_id && (
                  <Vimeo
                    video={event.participation_form.vimeo_id}
                    style={{ width: '100%' }}
                    className="vimeo-video mb-4"
                  />
                )}
                <p className="smaller-p mb-0">
                  <b>Questions</b>
                </p>
                <Table responsive className="table-stats-tracks">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {event.participation_form.questions.map(
                      (question, index) => (
                        <tr key={index}>
                          <td>{question.title}</td>
                          <td>
                            {question.type.title}
                            {question.answers && (
                              <>
                                {question.answers.map((answer, indexI) => (
                                  <div key={indexI} className="d-block">
                                    <SoftBadge bg="primary" className="me-2">
                                      {answer.title}
                                    </SoftBadge>
                                  </div>
                                ))}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <Button
                  variant="primary"
                  className="admin-primary"
                  size="sm"
                  onClick={() => {
                    navigate(
                      `/administration/evenements/${event.id}/modifier?back=/administration/evenements/${event.id}`
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Modifier
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="ms-2"
                  onClick={() => {
                    setShowSurvey(false);
                  }}
                >
                  Fermer
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </>
      )}
    </>
  );
};

export default EventShow;
