import React from 'react';
import Section from '../../../common/Section';
import { Button, Col, Row } from 'react-bootstrap';
import bgGroupementPres from '../../../../assets/img/tredunion/bg_groupement_pres-1.jpg';
import bgGroupementPresBottom from '../../../../assets/img/tredunion/bg_groupement_pres-2.jpg';
import downloadPdf from '../../../../assets/img/tredunion/download-pdf.png';
import separateurAdherents from '../../../../assets/img/tredunion/separateur-adherents.jpeg';
import * as Moment from 'moment/moment';
import { Link } from 'react-router-dom';

const CatalogueFormations = () => {
  return (
    <>
      <Row className="justify-content-center mb-5">
        <Col xs={6}>
          <hr className="mt-0" style={{ color: 'rgb(216 226 239 / 42%)' }} />
        </Col>
        <Col xs={12}></Col>
        <Col xs={12} className="mt-3">
          <h2 className="text-center">Les formations Tred Union</h2>
          <p className="mt-3 pb-3 text-center">
            <small>
              Découvrez le catalogue des formations proposées par Tred Union
            </small>
          </p>
        </Col>
        <Col lg={11} xl={9}>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <Button
                variant="primary"
                size="lg"
                className="big"
                onClick={() => {
                  window.open(
                    'https://tredunion.fr/catalogue-formations.pdf',
                    '_blank'
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    position: 'relative',
                    top: '-2px',
                    marginRight: '5px'
                  }}
                >
                  <path
                    d="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM13 12V8H11V12H8L12 16L16 12H13Z"
                    fill="rgba(255,255,255,1)"
                  ></path>
                </svg>
                Télécharger le catalogue
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CatalogueFormations;
