import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Spinner,
  Table,
  Alert,
  ButtonGroup,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import ExportPurchaseCodesFromIndex from '../../../../../exports/purchaseCodesIndex';
import {
  DeleteResource,
  ExportStatsVues,
  IndexResourceAdmin,
  PutResource,
  ShowResourceAdmin
} from '../../../../../services/ResourceService';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from '../../../../common/SoftBadge';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Link, useOutletContext } from 'react-router-dom';
import ExportTracks from '../../../../../exports/tracks';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearch } from '../../../../../redux/slices/resourcesSlice';
import moment from 'moment/moment';
import ExportScoringCanvas from '../companies/exportScoring/offcanvas';
import ExportStatsResources from './exportStats/offcanvas';
import { ExportScoring } from '../../../../../services/CompanyService';
import IconAlert from '../../../../common/IconAlert';

const IndexResources = () => {
  const [resourcesNotModerated, setResourcesNotModerated] = useOutletContext();
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [showStats, setShowStats] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResource, setSelectedResource] = useState(false);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [showExportPDF, setShowExportPDF] = useState(false);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(false);
  const [formData, setFormData] = useStateWithCallbackLazy({
    search: '',
    is_moderated: 0
  });

  const searchStored = useSelector(
    state => state.persistedResourcesReducer.search
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchObjects = async () => {
      setIsLoading(true);
      const responseObjects = await IndexResourceAdmin();
      if (responseObjects.success === true) {
        setInitialApiObjects(responseObjects.data);
        setInitialObjects(responseObjects.data);
        transformObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  useEffect(() => {
    setFormData({ ...formData, search: searchStored });

    if (searchStored !== '') {
      updateSearch(
        { search: searchStored, is_moderated: 0 },
        initialObjects,
        true
      );
    }
  }, [searchStored, initialObjects]);

  const exportStats = async form => {
    setExportIsLoading(true);
    const responseExport = await ExportStatsVues(form);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const updateSearch = (data, objects, resetPagination) => {
    if (data.search.length === 0 && data.is_moderated === 0) {
      setObjects(Array.from(objects));
    } else {
      if (resetPagination === true) {
        setTablePageIndex(0);
      }
      let tmpFilterObjects = objects;

      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.titleString &&
              obj.titleString.toLowerCase().includes(tmpSearch)) ||
            (obj.extension &&
              obj.extension.toLowerCase().includes(tmpSearch)) ||
            (obj.user &&
              typeof obj.user === 'string' &&
              obj.user.toLowerCase().includes(tmpSearch)) ||
            (obj.created_at && obj.created_at.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }

      if (data.is_moderated === 1) {
        tmpFilterObjects = tmpFilterObjects.filter(
          obj => obj.is_moderated === 0
        );
      }

      setObjects(Array.from(tmpFilterObjects));
    }
  };

  const transformObjects = objects => {
    let transformedApiObjects = objects.map(obj => {
      return {
        is_moderated: obj.is_moderated,
        titleString: obj.title,
        title: !obj.is_moderated ? (
          <>
            <SoftBadge bg="warning" className="me-2 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                  fill="rgba(157,82,40,1)"
                />
              </svg>
              Non visible
            </SoftBadge>
            <div className="d-block mb-1">{obj.title}</div>
            <Alert variant="warning">
              Ce fichier a été importé par un partenaire et n'a pas encore été
              validé par un administrateur. Vous pouvez valider le fichier et le
              rendre visible par les adhérents en cliquant sur le bouton
              ci-dessous :
              <Button
                size="sm"
                variant="falcon-default"
                className="mt-2 d-block"
                type="button"
                onClick={() => {
                  setShowValidate(true);
                  setSelectedResource(obj);
                }}
              >
                <FontAwesomeIcon icon={faCheck} /> Valider le fichier
              </Button>
            </Alert>
          </>
        ) : (
          obj.title
        ),
        roles:
          obj.roles && obj.roles.length > 0
            ? obj.roles.map(role => role.title)
            : [],
        extension: obj.file_url ? obj.file_url.split('.').pop() : '',
        user: obj.partner
          ? obj.partner.name
          : obj.user && obj.user.is_admin
          ? 'Administrateur'
          : '',
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        actions: (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">Statistiques</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="falcon-default"
                className="mb-2 d-block"
                type="button"
                onClick={async () => {
                  const response = await ShowResourceAdmin(obj.id);
                  if (response.success === true) {
                    setSelectedResource(response.data);
                    setShowStats(true);
                  } else {
                    toast(
                      'Une erreur est survenue, veuillez réessayer plus tard'
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="overlay-trigger-example">Modifier</Tooltip>}
            >
              <Button
                size="sm"
                variant="primary"
                className="me-2 d-block mb-2 admin-primary"
                type="button"
                onClick={() => {
                  window.location.href = `/administration/ressources/${obj.id}/modifier`;
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">Télécharger</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="primary"
                className="me-2 admin-primary mb-2 d-block"
                type="button"
                onClick={() => {
                  window.open(obj.file_url);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="overlay-trigger-example">Archiver</Tooltip>}
            >
              <Button
                size="sm"
                variant="danger"
                className="me-0 mb-2"
                type="button"
                onClick={() => {
                  setShowDelete(true);
                  setSelectedResource(obj);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </>
        )
      };
    });
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  const validateObject = async () => {
    setIsLoading(true);
    const response = await PutResource(selectedResource.id);
    if (response.success === true) {
      setShowValidate(false);
      toast('Ressource validée');
      setResourcesNotModerated(resourcesNotModerated - 1);
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const deleteObject = async () => {
    setIsLoading(true);
    const response = await DeleteResource(selectedResource.id);
    if (response.success === true) {
      setShowDelete(false);
      if (selectedResource.is_moderated === 0) {
        setResourcesNotModerated(resourcesNotModerated - 1);
      }
      toast('Ressource archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  return (
    <>
      <Offcanvas
        show={showStats}
        onHide={() => {
          setShowStats(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Statistiques du fichier</Offcanvas.Title>
        </Offcanvas.Header>
        {selectedResource.tracks !== null &&
        selectedResource.tracks !== undefined &&
        selectedResource.tracks.length > 0 ? (
          <Offcanvas.Body>
            <>
              <Table responsive className="table-stats-tracks">
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Initiateur</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedResource.tracks.map((track, index) => (
                    <tr key={index}>
                      <td>
                        {track.category.title === 'downloaded'
                          ? 'Téléchargé'
                          : 'Vu'}
                      </td>
                      <td>
                        {track.user !== undefined && track.user !== null && (
                          <>
                            {track.user.lastname} {track.user.firstname}
                            {track.user.main_company !== undefined &&
                              track.user.main_company !== null && (
                                <> ({track.user.main_company.name})</>
                              )}
                          </>
                        )}
                      </td>
                      <td>
                        {Moment(track.created_at).local().format('DD/MM/YYYY')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ExportTracks tracks={selectedResource.tracks} />
            </>
          </Offcanvas.Body>
        ) : (
          <Offcanvas.Body>Aucune statistique pour le moment.</Offcanvas.Body>
        )}
      </Offcanvas>
      <Offcanvas
        show={showValidate}
        onHide={() => {
          setShowValidate(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedResource.id !== undefined && (
              <>
                La validation de la ressource "{selectedResource.title}" est
                définitive.
                <br />
                Les membres Tred Union auront accès à cette ressource depuis
                leur espace, onglet "Tred Ressources".
              </>
            )}
            <br />
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              validateObject();
            }}
          >
            Oui, valider le fichier
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowValidate(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedResource.id !== undefined && (
              <>
                L'archivage de la ressource "{selectedResource.title}" est
                définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="justify-content-between align-items-center mb-5">
              <Col lg={5}>
                <h3 className="text-primary admin-title mb-0">
                  Gestion des ressources
                </h3>
              </Col>
              <Col xs={7} className="text-end">
                <DropdownButton
                  as={ButtonGroup}
                  variant="falcon-default"
                  size="sm"
                  title={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>{' '}
                      Export statistiques
                    </>
                  }
                  className="me-2"
                >
                  <ExportStatsResources
                    exportStats={exportStats}
                    setExportUrl={setExportUrl}
                    exportIsLoading={exportIsLoading}
                  />
                </DropdownButton>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="me-2"
                  as={Link}
                  to="/administration/ressources/categories"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
                      fill="rgba(75,74,77,1)"
                    />
                  </svg>
                  Gérer les catégories
                </Button>
                <Button
                  variant="primary"
                  className="admin-primary"
                  size="sm"
                  as={Link}
                  to="/administration/ressources/ajouter"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Ajouter une ressource
                </Button>
              </Col>
            </Row>
            <Row>
              <Row>
                {exportIsLoading === true && (
                  <Col xs={12} className="mt-2">
                    <Spinner animation="border" role="status" size="xxs">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                )}
                {exportUrl && (
                  <Col xs={12} className="mt-2">
                    <IconAlert
                      variant="success"
                      dismissible
                      onClose={() => setExportUrl('')}
                      className="mt-3"
                    >
                      <p className="mb-0">
                        Export des statistiques disponible :{' '}
                        <a href={exportUrl}>cliquez ici pour le télécharger</a>
                      </p>
                    </IconAlert>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12} className="mb-3">
                  <Form.Label>Recherche</Form.Label>
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Rechercher quelque chose..."
                    value={formData.search || ''}
                    onChange={event => {
                      dispatch(changeSearch(event.target.value));
                      setFormData(
                        {
                          ...formData,
                          search: event.target.value
                        },
                        data => {
                          updateSearch(data, initialObjects, true);
                        }
                      );
                    }}
                  />
                </Col>
                {resourcesNotModerated > 0 && (
                  <Col>
                    <label>
                      <input
                        type="checkbox"
                        name="is_moderated"
                        value={formData.is_moderated || ''}
                        className="me-1"
                        checked={
                          formData.is_moderated === 1 ||
                          formData.is_moderated === '1'
                        }
                        onChange={event => {
                          setFormData(
                            {
                              ...formData,
                              is_moderated: event.target.checked ? 1 : 0
                            },
                            data => {
                              updateSearch(data, initialObjects, true);
                            }
                          );
                        }}
                      />
                      Afficher uniquement les ressources en attente de
                      validation{' '}
                      <SoftBadge pill bg="warning" className="ms-0">
                        {resourcesNotModerated}
                      </SoftBadge>
                    </label>
                  </Col>
                )}
              </Row>
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <TableResources
                      data={objects}
                      tablePageIndex={tablePageIndex}
                      setTablePageIndex={setTablePageIndex}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function TableResources({ data, tablePageIndex, setTablePageIndex }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
        Header: 'Désignation'
      },
      {
        accessor: 'roles',
        Header: 'Autorisations'
      },
      {
        accessor: 'extension',
        Header: 'Extension'
      },
      {
        accessor: 'user',
        Header: 'Mis en ligne par'
      },
      {
        accessor: 'created_at',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: tablePageIndex, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageIndex(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportPurchaseCodesFromIndex purchaseCodes={data} />
      </div>
    </>
  );
}

TableResources.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

export default IndexResources;
