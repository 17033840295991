import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../../../../assets/scss/admin.scss';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/fr';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const CalendarAdmin = ({
  events,
  categories,
  formSearch,
  setFormSearch,
  setSelectedDate
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [changeViewFilter, setChangeViewFilter] = useState('Changer de vue');
  const [calendarChangeTrigger, setCalendarChangeTrigger] = useState(0);

  const handleCalendarChange = () => {
    // Cela incrémente l'état, déclenchant l'effet de réappliquer les filtres
    setCalendarChangeTrigger(prev => prev + 1);
  };

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const handleEventClick = object => {
    window.location.href = `/administration/evenements/${object.event.id}`;
  };

  const viewName = [
    'Affichage par mois',
    'Affichage semaine',
    'Affichage journée'
    //'Vue liste'
  ];
  const handleFilter = filter => {
    setChangeViewFilter(filter);
    handleCalendarChange();
    switch (filter) {
      case 'Affichage par mois':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Affichage semaine':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Affichage journée':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  useEffect(() => {
    let tredunionActive = false;
    let poleActive = false;
    let formationActive = false;
    let reunionActive = false;
    let webinarsActive = false;

    let eventsFiltered = events.filter(event => {
      const categoryName = event.category?.name.toUpperCase();
      const isHoliday = event.category?.is_holiday === 0;
      const isPublicHoliday = event.category?.is_public_holiday === 0;

      // Déterminez si chaque filtre est actif
      tredunionActive = formSearch.tredunion === 1;
      poleActive = formSearch.pole === 1;
      formationActive = formSearch.formations === 1;
      reunionActive = formSearch.reunions === 1;
      webinarsActive = formSearch.webinars === 1;

      // Appliquez la logique de filtrage en fonction des combinaisons de filtres
      if (tredunionActive && formationActive) {
        // Exclure "POLE" si "Tred Union" et "Formation" sont cochés
        return (
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday &&
          categoryName.includes('FORMATION')
        );
      } else if (tredunionActive && webinarsActive) {
        return (
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday &&
          categoryName.includes('WEBINAIRE')
        );
      } else if (tredunionActive && reunionActive) {
        // Toutes les réunions sauf celles de "POLE"
        return (
          !categoryName.includes('POLE') && categoryName.includes('REUNION')
        );
      } else if (poleActive && formationActive) {
        // Supposons que "FORMATION" chez "POLE" n'existe pas, donc aucun événement n'est retourné
        return false;
      } else if (poleActive && reunionActive) {
        // Afficher uniquement les événements de "POLE" (réunions)
        return (
          categoryName.includes('POLE') && categoryName.includes('REUNION')
        );
      } else {
        // Logique de filtrage par défaut si aucune des combinaisons spécifiques n'est sélectionnée
        const matchesTredunion =
          tredunionActive &&
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday;
        const matchesPole = poleActive && categoryName.includes('POLE');
        const matchesFormation =
          formationActive && categoryName.includes('FORMATION');
        const matchesReunion =
          reunionActive && categoryName.includes('REUNION');
        const matchesWebinars =
          webinarsActive && categoryName.includes('WEBINAIRE');

        return (
          matchesTredunion ||
          matchesPole ||
          matchesFormation ||
          matchesReunion ||
          matchesWebinars
        );
      }
    });

    //setCurrentEvents(eventsFiltered);

    if (calendarApi.currentDataManager) {
      if (
        !tredunionActive &&
        !poleActive &&
        !formationActive &&
        !reunionActive &&
        !webinarsActive
      ) {
        calendarApi
          .getEventSources()
          .forEach(eventSource => eventSource.remove());
        calendarApi.addEventSource(events);
      } else {
        calendarApi
          .getEventSources()
          .forEach(eventSource => eventSource.remove());
        calendarApi.addEventSource(eventsFiltered);
      }
    }
    console.log(events);
  }, [formSearch, calendarChangeTrigger, events]);

  let filterEvents = () => {};

  return (
    <>
      <Card className="mt-4 calendar-tred">
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col>
              <h6>
                <b>Filtrer :</b>
              </h6>
            </Col>
          </Row>
          <Row className="align-items-center gx-0 mb-4">
            <Col>
              <label className="me-3">
                <input
                  type="checkbox"
                  name="tredunion"
                  value={formSearch.tredunion}
                  className="me-1"
                  checked={
                    formSearch.tredunion === 1 || formSearch.tredunion === '1'
                  }
                  onChange={event => {
                    setFormSearch({
                      ...formSearch,
                      tredunion: event.target.checked ? 1 : 0
                    });
                  }}
                />
                Événements Tred Union{' '}
              </label>
              <label className="me-3">
                <input
                  type="checkbox"
                  name="pole"
                  value={formSearch.pole}
                  className="me-1"
                  checked={formSearch.pole === 1 || formSearch.pole === '1'}
                  onChange={event => {
                    setFormSearch({
                      ...formSearch,
                      pole: event.target.checked ? 1 : 0
                    });
                  }}
                />
                Événements Pole{' '}
              </label>
              <label className="me-3">
                <input
                  type="checkbox"
                  name="pole"
                  value={formSearch.formations}
                  className="me-1"
                  checked={
                    formSearch.formations === 1 || formSearch.formations === '1'
                  }
                  onChange={event => {
                    setFormSearch({
                      ...formSearch,
                      formations: event.target.checked ? 1 : 0
                    });
                  }}
                />
                Formations{' '}
              </label>
              <label className="me-3">
                <input
                  type="checkbox"
                  name="pole"
                  value={formSearch.reunions}
                  className="me-1"
                  checked={
                    formSearch.reunions === 1 || formSearch.reunions === '1'
                  }
                  onChange={event => {
                    setFormSearch({
                      ...formSearch,
                      reunions: event.target.checked ? 1 : 0
                    });
                  }}
                />
                Réunions{' '}
              </label>
              <label className="me-3">
                <input
                  type="checkbox"
                  name="pole"
                  value={formSearch.webinars}
                  className="me-1"
                  checked={
                    formSearch.webinars === 1 || formSearch.webinars === '1'
                  }
                  onChange={event => {
                    setFormSearch({
                      ...formSearch,
                      webinars: event.target.checked ? 1 : 0
                    });
                  }}
                />
                Webinars{' '}
              </label>
            </Col>
          </Row>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    const selectedDate = Moment(
                      calendarApi.getCurrentData().currentDate
                    )
                      .endOf('month')
                      .local()
                      .format('YYYY-MM-DD');
                    localStorage.setItem(
                      'selectedDateTredUnionAdmin',
                      selectedDate
                    );
                    setSelectedDate(selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                    handleCalendarChange();
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Next</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    const selectedDate = Moment(
                      calendarApi.getCurrentData().currentDate
                    )
                      .endOf('month')
                      .local()
                      .format('YYYY-MM-DD');
                    localStorage.setItem(
                      'selectedDateTredUnionAdmin',
                      selectedDate
                    );
                    setSelectedDate(selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                    handleCalendarChange();
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}{' '}
              </h4>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col className="d-flex justify-content-start order-md-2 ms-3">
              <Button
                className="me-2"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
                onClick={() => {
                  calendarApi.today();
                  const selectedDate = Moment(
                    calendarApi.getCurrentData().currentDate
                  )
                    .endOf('month')
                    .local()
                    .format('YYYY-MM-DD');
                  localStorage.setItem(
                    'selectedDateTredUnionAdmin',
                    selectedDate
                  );
                  setSelectedDate(selectedDate);
                  setTitle(calendarApi.getCurrentData().viewTitle);
                  handleCalendarChange();
                }}
              >
                Aujourd'hui
              </Button>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={changeViewFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            eventOrder={['start', 'time']}
            locale={esLocale}
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={10}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={events.filter(
              event => event.is_validated === 1 || event.is_validated === '1'
            )}
            initialDate={
              localStorage.getItem('selectedDateTredUnionAdmin')
                ? new Date(localStorage.getItem('selectedDateTredUnionAdmin'))
                : new Date()
            }
          />
          {categories.length > 0 && (
            <Row className="px-2 py-3">
              <Col xs={12}>
                {categories.filter(
                  cat => cat.name === 'Vacances scolaires Zone A'
                ).length > 0 && (
                  <span className="smaller-p">
                    <span
                      className="rounded-circle d-inline-block me-1"
                      style={{
                        backgroundColor: categories.filter(
                          cat => cat.name === 'Vacances scolaires Zone A'
                        )[0].color,
                        height: '12px',
                        width: '12px',
                        position: 'relative',
                        top: '1px'
                      }}
                    ></span>
                    Vacances scolaires Zone A
                  </span>
                )}
                {categories.filter(
                  cat => cat.name === 'Vacances scolaires Zone B'
                ).length > 0 && (
                  <span className="smaller-p ms-2">
                    <span
                      className="rounded-circle d-inline-block me-1"
                      style={{
                        backgroundColor: categories.filter(
                          cat => cat.name === 'Vacances scolaires Zone B'
                        )[0].color,
                        height: '12px',
                        width: '12px',
                        position: 'relative',
                        top: '1px'
                      }}
                    ></span>
                    Vacances scolaires Zone B
                  </span>
                )}
                {categories.filter(
                  cat => cat.name === 'Vacances scolaires Zone C'
                ).length > 0 && (
                  <span className="smaller-p ms-2">
                    <span
                      className="rounded-circle d-inline-block me-1"
                      style={{
                        backgroundColor: categories.filter(
                          cat => cat.name === 'Vacances scolaires Zone C'
                        )[0].color,
                        height: '12px',
                        width: '12px',
                        position: 'relative',
                        top: '1px'
                      }}
                    ></span>
                    Vacances scolaires Zone C
                  </span>
                )}
              </Col>
              <Col xs={12} className="mt-2">
                {categories
                  .filter(
                    cat => cat.is_holiday === 0 && cat.is_public_holiday === 0
                  )
                  .map((category, index) => (
                    <span className="smaller-p me-2" key={index}>
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: category.color,
                          height: '12px',
                          width: '12px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      {category.name}
                    </span>
                  ))}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

// écris-moi les proptypes de CalendarAdmin
CalendarAdmin.propTypes = {
  events: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  formSearch: PropTypes.object.isRequired,
  setFormSearch: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired
};

export default CalendarAdmin;
