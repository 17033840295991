import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../common/IconButton';
import moment from 'moment';
import { contractMetaList } from '../metaList';
import { filterColumns } from './functions';

const ExportContracts = ({
  objects,
  selectedYears,
  selectedRfa,
  selectedCaa,
  selectedSalon
}) => {
  const [cleanData, setCleanData] = useState([]);
  const [filteredMetas, setFilteredMetas] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    setFilteredMetas(
      contractMetaList.filter(meta => {
        return filterColumns(
          meta,
          selectedYears,
          selectedRfa,
          selectedCaa,
          selectedSalon
        );
      })
    );
    objects.map(contract => {
      let cleanedCode = {
        name: contract.partner?.name
      };
      filteredMetas.map((meta, index) => {
        let m = contract.metas?.find(
          metaContract => metaContract.key === meta.key
        );
        if (m) {
          if (meta.type === 'date') {
            cleanedCode[meta.key] = moment(m.value).format('DD/MM/YYYY');
          } else if (meta.key.includes('etat_facture_')) {
            switch (m.value) {
              case '1':
                cleanedCode[meta.key] = 'Demander une facture';
                break;
              case '2':
                cleanedCode[meta.key] = 'Facture à déposer';
                break;
              case '3':
                cleanedCode[meta.key] = 'Impayée';
                break;
              case '4':
                cleanedCode[meta.key] = 'Payée';
                break;
              case '5':
                cleanedCode[meta.key] = 'Annulée';
                break;
            }
          } else {
            cleanedCode[meta.key] = m.value;
          }
        }
      });
      cleanedCode.updated_at = `${contract.user?.firstname} ${
        contract.user?.lastname
      } le ${moment(contract.updated_at).format('DD/MM/YYYY')}`;
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [objects, selectedYears, selectedRfa, selectedCaa, selectedSalon]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Export Excel
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Journal">
              <ExcelColumn label="Partenaire" value="name" />
              {filteredMetas.map((meta, index) => (
                <ExcelColumn label={meta.label} value={meta.key} key={index} />
              ))}
              <ExcelColumn label="Dernière modification" value="updated_at" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportContracts.propTypes = {
  objects: PropTypes.array,
  selectedYears: PropTypes.array,
  selectedRfa: PropTypes.bool,
  selectedCaa: PropTypes.bool,
  selectedSalon: PropTypes.bool
};

export default ExportContracts;
