import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import ReactExport from 'react-data-export';
import moment from 'moment';
import { IndexToExport } from '../services/CompanyService';
import { toast } from 'react-toastify';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportCompanies = ({ exp }) => {
  const [multiDataSet, setMultiDataSet] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObject = await IndexToExport();
      if (responseObject.success === true) {
        let companies = responseObject.data;
        const columns = [
          { title: 'ASSOCIE / MEMBRE', width: { wpx: 250 } },
          { title: '' },
          { title: 'Adresse', width: { wpx: 300 } },
          { title: 'CP' },
          { title: 'VILLE', width: { wpx: 250 } },
          { title: 'Prénom', width: { wpx: 200 } },
          { title: 'NOM', width: { wpx: 200 } },
          { title: 'Tél', width: { wpx: 200 } },
          { title: 'GSM', width: { wpx: 200 } },
          { title: 'Mail', width: { wpx: 250 } }
        ];
        let data = [];
        let companiesWithChildrens = [];
        companies.map(company => {
          companiesWithChildrens.push(company);
          if (company.childrens && company.childrens.length > 0) {
            company.childrens.map(children =>
              companiesWithChildrens.push(children)
            );
          }
        });
        let roles = [];
        if (exp.roles && exp.roles.length > 0) {
          exp.roles.map(e => roles.push(e.id));
        }
        companiesWithChildrens.map(company => {
          let tmpUsers = [];
          company.main_users.map(user => {
            if (user.roles && user.roles.length > 0) {
              if (
                user.roles.filter(role => roles.includes(role.id)).length > 0
              ) {
                tmpUsers.push(user);
              }
            }
          });
          company.users.map(user => {
            if (user.roles && user.roles.length > 0) {
              if (
                user.roles.filter(role => roles.includes(role.id)).length > 0
              ) {
                tmpUsers.push(user);
              }
            }
          });
          if (tmpUsers.length === 0) {
            data.push([
              {
                value: company.name || '',
                style: {
                  font: { bold: true },
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: company.headquarter_id ? 'Filiale' : 'Siège',
                style: {
                  font: { bold: true },
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: company.address || '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: company.zip_code || '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: company.city || '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              },
              {
                value: '',
                style: {
                  fill: !company.headquarter_id
                    ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                    : ''
                }
              }
            ]);
          } else {
            tmpUsers.map(user => {
              data.push([
                {
                  value: company.name || '',
                  style: {
                    font: { bold: true },
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: company.headquarter_id ? 'Filiale' : 'Siège',
                  style: {
                    font: { bold: true },
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: company.address || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: company.zip_code || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: company.city || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: user.firstname || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: user.lastname || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: user.phone || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: user.mobile_phone || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                },
                {
                  value: user.email || '',
                  style: {
                    fill: !company.headquarter_id
                      ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                      : ''
                  }
                }
              ]);
            });
          }
        });
        setMultiDataSet([
          {
            columns: columns,
            data: data
          }
        ]);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    // map sur chaque entreprise
    // filter sur chaque user where has export ID = exp.id
    // si length filter === 0, afficher les infos de l'entreprise vide
    // map sur les users filtrés
    // puis map sur chaque entreprise enfant
    // filter sur chaque user where has export ID = exp.id
    // si length filter === 0, afficher les infos de l'entreprise vide
    // map sur les users filtrés
  }, [exp.id]);
  return (
    <>
      {multiDataSet.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="w-auto me-2"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Télécharger
              </IconButton>
            }
          >
            <ExcelSheet
              dataSet={multiDataSet}
              name={`TRED UNION ${moment().format('DDMMYYYY')}`}
            ></ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportCompanies.propTypes = {
  exp: PropTypes.object
};

export default ExportCompanies;
