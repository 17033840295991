import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import NavbarTopDropDownMenus from '../navbar/top/NavbarTopDropDownMenus';
import { topNavbarBreakpoint } from 'config';
import logoTredUnion from 'assets/img/tredunion/logo-TRED-UNION.png';
import logoPole from 'assets/img/tredunion/logo-pole.jpeg';
import logoFacebook from 'assets/img/tredunion/facebook-48.png';
import logoTwitter from 'assets/img/tredunion/logo-twitter-encadre-48.png';
import NineDotMenu from '../navbar/top/NineDotMenu';
import MagicBellNotifications from '../navbar/top/MagicBell';

const NavbarStandard = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant="light"
      fixed="top"
      expand={topNavbarBreakpoint}
      className="navbar-standard navbar-theme bg-100 bg-light"
    >
      <Container className="py-2">
        <Navbar.Brand
          className="text-white dark__text-white navbar-logo"
          as={Link}
          to="/"
        >
          <img
            style={{ maxWidth: '122px' }}
            src={logoTredUnion}
            title="Logo Tred Union"
            onClick={() => {
              if (window.location.pathname === '/') {
                document.getElementById('accueil').scrollIntoView();
              }
            }}
          />
        </Navbar.Brand>
        <span className="title-after-logo mx-3 d-none d-xl-block">
          <span className="d-block">Groupement de transporteurs</span>
          <span className="d-block">routiers et logistiques</span>
        </span>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar justify-content-end pt-3">
          <Nav>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
        </Navbar.Collapse>
        {localStorage.getItem('accessToken') && localStorage.getItem('user') && (
          <div id="ninedotscontainer">
            <NineDotMenu />
          </div>
        )}
        {localStorage.getItem('user') &&
          JSON.parse(localStorage.getItem('user')).magic_bell_uuid !==
            undefined &&
          JSON.parse(localStorage.getItem('user')).magic_bell_uuid !== null && (
            <Nav
              navbar
              className="navbar-nav-icons ms-auto flex-row align-items-center"
              as="ul"
            >
              <span
                style={{
                  position: 'relative',
                  bottom: '-7px',
                  right: '4px',
                  marginLeft: '12px'
                }}
              >
                <MagicBellNotifications />
              </span>
            </Nav>
          )}
        <div className="sub-header-logos">
          <a
            href="https://groupe-pole.com/"
            rel="noreferrer"
            target="_blank"
            className="pe-3"
          >
            <img src={logoPole} />
          </a>
          <a
            href="https://www.facebook.com/pages/Tred-Union/252243268201674?fref=ts"
            rel="noreferrer"
            target="_blank"
          >
            <img className="social" src={logoFacebook} />
          </a>
          <a
            href="https://twitter.com/tred_union"
            rel="noreferrer"
            target="_blank"
          >
            <img className="social" src={logoTwitter} />
          </a>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
