import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'contracts';

export async function IndexContract(readOnly = false) {
  if (readOnly) {
    return modelService.IndexModel(`${model}/from-member`);
  } else {
    return modelService.IndexModel(`${model}`);
  }
}

export async function PostContract(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutContract(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteContract(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
