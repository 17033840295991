import React from 'react';
import { useOutletContext } from 'react-router-dom';
import IndexContracts from '../admins/contracts';

const Contrats = () => {
  const [user] = useOutletContext();

  return <IndexContracts readOnly={true} />;
};

export default Contrats;
