import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FilterSelect = ({
  initialContracts,
  partners,
  setSearchData,
  searchData,
  metaKey,
  setContracts
}) => {
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  useEffect(() => {
    if (setContracts) {
      let newContracts = [...initialContracts];
      searchData.map(data => {
        newContracts = newContracts.filter(contract => {
          let r = false;
          if (data.key === 'partner_id') {
            r = contract.partner.id === data.value;
          } else if (
            data.key.includes(
              'etat_facture_caa_rfa_' ||
                data.key.includes('etat_facture_salon_')
            )
          ) {
            let value = contract.metas?.find(
              meta => meta.key === data.key
            )?.value;
            if (value) {
              switch (value) {
                case '1':
                  value = 'Demander une facture';
                  break;
                case '2':
                  value = 'Facture à déposer';
                  break;
                case '3':
                  value = 'Impayée';
                  break;
                case '4':
                  value = 'Payée';
                  break;
                case '5':
                  value = 'Annulée';
                  break;
              }
            }
            r = value === data.value;
          } else {
            r = contract.metas
              ?.find(meta => meta.key === data.key)
              ?.value.includes(data.value);
          }
          return r;
        });
      });
      setContracts(newContracts);
    }
  }, [searchData]);

  return (
    <>
      {metaKey === 'name' ? (
        <>
          <Select
            closeMenuOnSelect={true}
            placeholder="Choisir le partenaire..."
            options={partners.filter(partner => {
              if (
                initialContracts.find(
                  contract => contract.partner.id === partner.value
                )
              ) {
                return true;
              } else {
                return false;
              }
            })}
            name=""
            value={null}
            classNamePrefix="react-select"
            onChange={object => {
              setSearchData([
                ...searchData.filter(data => {
                  return data.key !== 'partner_id';
                }),
                {
                  key: 'partner_id',
                  value: parseInt(object.value)
                }
              ]);
            }}
          />{' '}
          {searchData.find(data => data.key === 'partner_id') && (
            <span
              className="cursor-pointer small d-block mt-2"
              onClick={() => {
                setSearchData(
                  [...searchData].filter(data => {
                    return data.key !== 'partner_id';
                  })
                );
              }}
            >
              {
                partners.find(
                  partner =>
                    partner.value ===
                    searchData.find(data => data.key === 'partner_id').value
                ).label
              }
              {' - '}
              Réinitialiser
            </span>
          )}
        </>
      ) : (
        <>
          <Select
            closeMenuOnSelect={true}
            placeholder="Choisir..."
            options={initialContracts
              .map(contract => {
                if (metaKey === 'etat_contrat') {
                  return contract.metas
                    ?.find(meta => meta.key === metaKey)
                    ?.value.replace(/le\s\d{2}\/\d{2}\/\d{4}/g, '');
                } else if (
                  metaKey.includes('etat_facture_caa_rfa_') ||
                  metaKey.includes('etat_facture_salon_')
                ) {
                  let value = contract.metas?.find(
                    meta => meta.key === metaKey
                  )?.value;
                  if (value) {
                    switch (value) {
                      case '1':
                        return 'Demander une facture';
                      case '2':
                        return 'Facture à déposer';
                      case '3':
                        return 'Impayée';
                      case '4':
                        return 'Payée';
                      case '5':
                        return 'Annulée';
                    }
                  }
                } else {
                  return contract.metas?.find(meta => meta.key === metaKey)
                    ?.value;
                }
              })
              .filter(onlyUnique)
              .filter(
                value => value !== '' && value !== null && value !== undefined
              )
              .map(value => {
                return {
                  value: value,
                  label: value
                };
              })
              .sort((a, b) => {
                if (a.value < b.value) {
                  return -1;
                } else {
                  return 1;
                }
              })}
            name=""
            value={null}
            classNamePrefix="react-select"
            onChange={object => {
              setSearchData([
                ...searchData.filter(data => {
                  return data.key !== metaKey;
                }),
                {
                  key: metaKey,
                  value: object.value
                }
              ]);
            }}
          />
        </>
      )}
    </>
  );
};

FilterSelect.propTypes = {
  initialContracts: PropTypes.array.isRequired,
  partners: PropTypes.array,
  setSearchData: PropTypes.func.isRequired,
  searchData: PropTypes.array.isRequired,
  metaKey: PropTypes.string.isRequired,
  setContracts: PropTypes.func
};
export default FilterSelect;
