export const filterColumns = (
  meta,
  selectedYears,
  selectedRfa,
  selectedCaa,
  selectedSalon
) => {
  if (meta.year) {
    if (
      (!selectedYears || selectedYears.length === 0) &&
      !selectedCaa &&
      !selectedRfa &&
      !selectedSalon
    ) {
      return true;
    }
    let r = true;
    if (selectedYears.length > 0) {
      r = selectedYears.includes(meta.year);
    }
    if (selectedCaa && selectedRfa && selectedSalon) {
      r =
        r && (meta.filterColsCaa || meta.filterColsRfa || meta.filterColsSalon);
    } else if (selectedCaa && selectedRfa) {
      r =
        r &&
        (meta.filterColsCaa || meta.filterColsRfa || meta.filterColsCaaPlusRfa);
    } else if (selectedCaa && selectedSalon) {
      r = r && (meta.filterColsCaa || meta.filterColsSalon);
    } else if (selectedRfa && selectedSalon) {
      r = r && (meta.filterColsRfa || meta.filterColsSalon);
    } else if (selectedCaa) {
      r = r && meta.filterColsCaa;
    } else if (selectedRfa) {
      r = r && meta.filterColsRfa;
    } else if (selectedSalon) {
      r = r && meta.filterColsSalon;
    }
    return r;
  } else {
    return true;
  }
};

export const filterDatas = (
  contract,
  selectedYears,
  selectedRfa,
  selectedCaa,
  selectedSalon
) => {
  if (selectedYears && selectedYears.length === 1) {
    const year = selectedYears[0];
    if (
      selectedCaa &&
      !selectedRfa &&
      !selectedSalon &&
      !contract.metas?.find(meta => meta.key === `montant_caa_${year}`)?.value
    ) {
      return false;
    } else if (
      !selectedCaa &&
      selectedRfa &&
      !selectedSalon &&
      !contract.metas?.find(meta => meta.key === `montant_rfa_${year}`)?.value
    ) {
      return false;
    } else if (
      selectedCaa &&
      selectedRfa &&
      !selectedSalon &&
      (!contract.metas?.find(meta => meta.key === `montant_caa_${year}`)
        ?.value ||
        !contract.metas?.find(meta => meta.key === `montant_rfa_${year}`)
          ?.value)
    ) {
      return false;
    } else if (
      !selectedCaa &&
      !selectedRfa &&
      selectedSalon &&
      !contract.metas?.find(meta => meta.key === `montant_salon_${year}`)?.value
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
