import React, { Fragment, useState } from 'react';
import { PostContractMeta } from '../../../../../services/Contract/ContractMetaService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import FormAlert from '../../../commons/FormAlert';
import Select from 'react-select';
import { PostContract } from '../../../../../services/Contract/ContractService';
import moment from 'moment';

const AddDereferencement = ({
  setParentTrigger,
  partners,
  initialContracts
}) => {
  const [form, setForm] = useState({
    partner_id: '',
    date: moment().format('YYYY-MM-DD')
  });
  const [showAddDereferencement, setShowAddDereferencement] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddPartner, setSelectedAddPartner] = useState({});
  const handleAddContractSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    let response = await PostContractMeta({
      contract_id: initialContracts.find(
        contract => contract.partner.id === form.partner_id
      )?.id,
      key: 'dereferencement_date',
      value: form.date
    });
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    if (response.success === true) {
      setIsLoading(false);
      setParentTrigger(Date.now());
      toast('Déréférencement ajouté avec succès');
      setForm({
        partner_id: '',
        date: ''
      });
      setSelectedAddPartner({});
      setShowAddDereferencement(false);
    } else {
      toast('Une erreur est survenu, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Button
        variant="outline-warning"
        className="admin-primary"
        size="sm"
        onClick={() => setShowAddDereferencement(true)}
      >
        Déréférencement
      </Button>
      <Offcanvas
        placement={'end'}
        show={showAddDereferencement}
        onHide={() => {
          setShowAddDereferencement(false);
          setForm({
            partner_id: '',
            date: ''
          });
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Déréférencement</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleAddContractSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>Choisir une société partenaire :</Form.Label>
              {partners?.length > 0 && (
                <>
                  <Select
                    closeMenuOnSelect={true}
                    placeholder="Choisir le partenaire..."
                    options={partners.filter(partner => {
                      if (
                        initialContracts.find(contract => {
                          if (contract.metas && contract.metas.length > 0) {
                            return (
                              contract.partner.id === partner.value /* &&
                              !contract.metas.find(
                                meta => meta.key === 'dereferencement_date'
                              )*/
                            );
                          } else {
                            return contract.partner.id === partner.value;
                          }
                        })
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    })}
                    name="partner_id"
                    classNamePrefix="react-select"
                    onChange={object => {
                      setForm({
                        ...form,
                        partner_id: parseInt(object.value)
                      });
                      setSelectedAddPartner(object);
                    }}
                    value={selectedAddPartner}
                  />
                </>
              )}
              <br />
              <Form.Label>Choisir la date :</Form.Label>
              <Form.Control
                type="date"
                placeholder="Valeur"
                name="value"
                id="meta-value"
                value={form.date}
                onChange={e => {
                  setForm({
                    ...form,
                    date: e.target.value
                  });
                }}
              />
              <br />
              <Button
                variant="outline-warning"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
                disabled={isLoading}
              >
                Enregistrer
              </Button>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

AddDereferencement.propTypes = {
  setParentTrigger: PropTypes.func,
  partners: PropTypes.array,
  initialContracts: PropTypes.array
};
export default AddDereferencement;
