import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  DeleteContract,
  IndexContract,
  PostContract
} from '../../../../../services/Contract/ContractService';
import {
  IndexPartner,
  IndexPartnerWithTrashed
} from '../../../../../services/PartnerService';
import MetaManage from './metaManage';
import AddContract from './addContract';
import FilterSelect from './filterSelect';
import AddDereferencement from './AddDereferencement ';
import { contractMetaList } from './metaList';
import { filterColumns, filterDatas } from './utils/functions';
import ExportContracts from './utils/export';
import EmailLogsContractsTable from './indexMails';
import PropTypes from 'prop-types';

const IndexContracts = ({ readOnly = false }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [partners, setPartners] = useState([]);
  const [initialContracts, setInitialContracts] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [order, setOrder] = useState({});
  const years = [2023, 2024, 2025];
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCaa, setSelectedCaa] = useState(false);
  const [selectedRfa, setSelectedRfa] = useState(false);
  const [selectedSalon, setSelectedSalon] = useState(false);

  useEffect(() => {
    if (order?.key) {
      setContracts(
        [...contracts].sort((a, b) => {
          switch (order.key) {
            case 'name':
              if (order.value === 'asc') {
                return a.partner.name.toLowerCase() <
                  b.partner.name.toLowerCase()
                  ? 1
                  : -1;
              } else {
                return a.partner.name.toLowerCase() >
                  b.partner.name.toLowerCase()
                  ? 1
                  : -1;
              }
            default:
              if (order.value === 'asc') {
                let aValue = a.metas?.find(
                  meta => meta.key === order.key
                )?.value;
                let bValue = b.metas?.find(
                  meta => meta.key === order.key
                )?.value;
                if (!isNaN(aValue) && !isNaN(bValue)) {
                  return parseFloat(aValue) < parseFloat(bValue) ? 1 : -1;
                } else {
                  return aValue?.toLowerCase() < bValue?.toLowerCase() ? 1 : -1;
                }
              } else {
                let aValue = a.metas?.find(
                  meta => meta.key === order.key
                )?.value;
                let bValue = b.metas?.find(
                  meta => meta.key === order.key
                )?.value;
                if (!isNaN(aValue) && !isNaN(bValue)) {
                  return parseFloat(aValue) > parseFloat(bValue) ? 1 : -1;
                } else {
                  return aValue?.toLowerCase() > bValue?.toLowerCase() ? 1 : -1;
                }
              }
          }
        })
      );
    }
  }, [order]);

  useEffect(() => {
    setIsLoading(true);
    const fetchContracts = async () => {
      const responseObjects = await IndexContract(readOnly);
      if (responseObjects.success === true) {
        setContracts(responseObjects.data);
        setInitialContracts(responseObjects.data);
        setIsLoading(false);
        setOrder({
          ...order
        });
        setSearchData([...searchData]);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPartners = async () => {
      const responseObject = await IndexPartnerWithTrashed();
      if (responseObject.success === true) {
        let selectObjects = [];
        responseObject.data.map(partner => {
          selectObjects.push({
            value: partner.id,
            label: partner.name
          });
        });
        setPartners(selectObjects);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchContracts();
    fetchPartners();
  }, [trigger]);

  const deleteContract = async () => {
    const response = await DeleteContract(selectedContract.id);
    if (response.success === true) {
      setShowDelete(false);
      setSelectedContract({});
      setTrigger(Date.now());
      toast('La ligne a bien été supprimée.');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <h3 className="text-primary admin-title mb-0">
                Contrats partenaires
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      <Card className="mt-3">
        <Card.Body className="">
          <Row>
            {!readOnly && (
              <Col xs={12}>
                <small>
                  <small>
                    <a href="#emails">Voir l'historique des emails</a>
                  </small>
                </small>
              </Col>
            )}
            {!readOnly && (
              <Col xs={12} className="mt-3">
                <label className="d-inline me-2">
                  <input
                    type="checkbox"
                    id="caa"
                    name="caa"
                    value="1"
                    className="me-1"
                    checked={selectedCaa === true}
                    onChange={() => {
                      setSelectedCaa(!selectedCaa);
                    }}
                  />
                  CAA
                </label>
                <label className="d-inline me-2">
                  <input
                    type="checkbox"
                    id="rfa"
                    name="rfa"
                    value="1"
                    className="me-1"
                    checked={selectedRfa === true}
                    onChange={() => {
                      setSelectedRfa(!selectedRfa);
                    }}
                  />
                  RFA
                </label>
                <label className="d-inline me-2">
                  <input
                    type="checkbox"
                    id="salon"
                    name="salon"
                    value="1"
                    className="me-1"
                    checked={selectedSalon === true}
                    onChange={() => {
                      setSelectedSalon(!selectedSalon);
                    }}
                  />
                  Salon
                </label>
              </Col>
            )}
            {!readOnly && (
              <Col xs={12} className="mt-3">
                {years.map((year, index) => (
                  <Fragment key={index}>
                    <label className="d-inline me-2">
                      <input
                        type="checkbox"
                        id={year}
                        name={year}
                        value="1"
                        className="me-1"
                        checked={
                          selectedYears.find(selectedYear => {
                            return selectedYear === year;
                          }) !== undefined
                        }
                        onChange={() => {
                          if (
                            selectedYears.find(selectedYear => {
                              return selectedYear === year;
                            }) !== undefined
                          ) {
                            setSelectedYears([
                              ...selectedYears.filter(selectedYear => {
                                return selectedYear !== year;
                              })
                            ]);
                          } else {
                            setSelectedYears([...selectedYears, year]);
                          }
                        }}
                      />
                      {year}
                    </label>
                  </Fragment>
                ))}
              </Col>
            )}
            {!readOnly && (
              <Col xs={6} className="mt-4">
                <AddContract
                  setParentTrigger={setTrigger}
                  partners={partners}
                />
              </Col>
            )}
            {!readOnly && (
              <Col xs={6} className="mt-4 text-end">
                <AddDereferencement
                  partners={partners}
                  setParentTrigger={setTrigger}
                  initialContracts={initialContracts}
                />
              </Col>
            )}
            {searchData.length > 0 && (
              <Col xs={12} className="mt-3">
                <p
                  className="smaller-p cursor-pointer text-decoration-underline"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <b>Réinitialiser tous les filtres</b>
                </p>
              </Col>
            )}
            <Col xs={12} className="mt-3 contracts">
              <div className="table-responsive">
                <table className="table table-bordered admin-table w-100 d-block d-table">
                  <thead>
                    <tr>
                      <th scope="col" key="name">
                        <span
                          className="cursor-pointer d-block mb-2"
                          onClick={() =>
                            setOrder({
                              key: 'name',
                              value: order.value === 'asc' ? 'desc' : 'asc'
                            })
                          }
                        >
                          Société Partenaire
                          {order.key === 'name' && (
                            <span className="ms-1">
                              {order.value === 'asc' ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                >
                                  <path
                                    d="M13.0001 7.82843V20H11.0001V7.82843L5.63614 13.1924L4.22192 11.7782L12.0001 4L19.7783 11.7782L18.3641 13.1924L13.0001 7.82843Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                >
                                  <path
                                    d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              )}
                            </span>
                          )}
                        </span>
                        <FilterSelect
                          partners={partners}
                          metaKey={'name'}
                          initialContracts={initialContracts}
                          searchData={searchData}
                          setSearchData={setSearchData}
                        />
                      </th>
                      {contractMetaList
                        .filter(meta => {
                          if (readOnly && meta.admin === false) {
                            return true;
                          } else if (readOnly) {
                            return false;
                          }
                          return filterColumns(
                            meta,
                            selectedYears,
                            selectedRfa,
                            selectedCaa,
                            selectedSalon
                          );
                        })
                        .map((meta, index) => (
                          <th scope="col" key={`${meta.key}`}>
                            <span className="d-block mb-2">
                              <span
                                className={`${
                                  (meta.filter || meta.sort) && 'cursor-pointer'
                                }`}
                                onClick={() => {
                                  if (meta.filter || meta.sort) {
                                    setOrder({
                                      key: meta.key,
                                      value:
                                        order.value === 'asc' ? 'desc' : 'asc'
                                    });
                                  }
                                }}
                              >
                                {meta.label}
                                {meta.key === 'facturation_email' ? (
                                  <small> séparées par des virgules</small>
                                ) : (
                                  ''
                                )}
                                {(meta.filter || meta.sort) &&
                                  order.key === meta.key && (
                                    <span className="ms-1">
                                      {order.value === 'asc' ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="12"
                                          height="12"
                                        >
                                          <path
                                            d="M13.0001 7.82843V20H11.0001V7.82843L5.63614 13.1924L4.22192 11.7782L12.0001 4L19.7783 11.7782L18.3641 13.1924L13.0001 7.82843Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="12"
                                          height="12"
                                        >
                                          <path
                                            d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      )}
                                    </span>
                                  )}
                              </span>
                              {searchData.find(
                                data =>
                                  data.key === meta.key && data.value !== ''
                              ) ? (
                                <span
                                  className="cursor-pointer small inline ms-2"
                                  onClick={() => {
                                    setSearchData(
                                      [...searchData].filter(data => {
                                        return data.key !== meta.key;
                                      })
                                    );
                                  }}
                                >
                                  {
                                    searchData.find(
                                      data =>
                                        data.key === meta.key &&
                                        data.value !== ''
                                    ).value
                                  }
                                  {' - '}
                                  Réinitialiser
                                </span>
                              ) : null}
                            </span>
                            {meta.filter && (
                              <FilterSelect
                                metaKey={meta.key}
                                initialContracts={initialContracts}
                                searchData={searchData}
                                setSearchData={setSearchData}
                                setContracts={setContracts}
                              />
                            )}
                          </th>
                        ))}
                      {!readOnly && <th scope="col">Dernière modification</th>}
                      {!readOnly && <th scope="col">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {contracts
                      ?.filter(contract =>
                        filterDatas(
                          contract,
                          selectedYears,
                          selectedRfa,
                          selectedCaa,
                          selectedSalon
                        )
                      )
                      .sort((a, b) => {
                        // D'abord comparer les noms des partenaires
                        const nameCompare = a.partner.name.localeCompare(
                          b.partner.name
                        );
                        if (nameCompare !== 0) {
                          return nameCompare;
                        }
                        // Si les noms des partenaires sont identiques, prioriser ceux sans specific_label
                        if (!a.specific_label && b.specific_label) {
                          return -1; // A vient avant B
                        } else if (a.specific_label && !b.specific_label) {
                          return 1; // B vient avant A
                        }
                        return 0; // Aucune différence si les deux ont ou n'ont pas de specific_label
                      })
                      .map((contract, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="fixed-column"
                              style={
                                contract.metas.find(
                                  meta => meta.key === 'dereferencement_date'
                                )
                                  ? {
                                      color: '#ff6699'
                                    }
                                  : {}
                              }
                            >
                              {contract.partner.name} {contract.specific_label}
                            </td>
                            {contractMetaList
                              .filter(meta => {
                                if (readOnly && meta.admin === false) {
                                  return true;
                                } else if (readOnly) {
                                  return false;
                                }
                                return filterColumns(
                                  meta,
                                  selectedYears,
                                  selectedRfa,
                                  selectedCaa,
                                  selectedSalon
                                );
                              })
                              .map((meta, index) => (
                                <td
                                  key={`${contract.id}-${meta.key}`}
                                  style={
                                    meta.key === 'etat_contrat' &&
                                    contract.metas?.find(
                                      metaContract =>
                                        metaContract.key === meta.key
                                    )?.value !== undefined
                                      ? {
                                          color: '#000000',
                                          textAlign: 'center',
                                          backgroundColor:
                                            contract.metas?.find(
                                              metaContract =>
                                                metaContract.key === meta.key
                                            )?.value === 'En cours de validité'
                                              ? '#66ff99'
                                              : contract.metas?.find(
                                                  metaContract =>
                                                    metaContract.key ===
                                                    meta.key
                                                )?.value === 'Expiré'
                                              ? '#ff6699'
                                              : '#c00000'
                                        }
                                      : meta.key.includes(
                                          'etat_facture_caa_rfa_'
                                        ) ||
                                        meta.key.includes('etat_facture_salon_')
                                      ? {
                                          backgroundColor:
                                            contract.metas?.find(
                                              metaContract =>
                                                metaContract.key === meta.key
                                            )?.value === '1'
                                              ? '#ffff00'
                                              : contract.metas?.find(
                                                  metaContract =>
                                                    metaContract.key ===
                                                    meta.key
                                                )?.value === '2'
                                              ? 'orange'
                                              : contract.metas?.find(
                                                  metaContract =>
                                                    metaContract.key ===
                                                    meta.key
                                                )?.value === '3'
                                              ? '#ff6699'
                                              : contract.metas?.find(
                                                  metaContract =>
                                                    metaContract.key ===
                                                    meta.key
                                                )?.value === '4'
                                              ? '#66ff99'
                                              : 'transparent'
                                        }
                                      : {}
                                  }
                                >
                                  <MetaManage
                                    setParentTrigger={setTrigger}
                                    contract={contract}
                                    metaKey={meta.key}
                                    value={
                                      contract.metas?.find(
                                        metaContract =>
                                          metaContract.key === meta.key
                                      )?.value
                                    }
                                    type={meta.type}
                                    isEditable={meta.editable}
                                    readOnly={readOnly}
                                  />
                                </td>
                              ))}
                            {!readOnly && (
                              <td className="fixed-column">
                                {contract.updated_at && (
                                  <span className="d-block">
                                    {contract.user?.id && (
                                      <span className="d-block">
                                        {contract.user.firstname}{' '}
                                        {contract.user.lastname} le{' '}
                                      </span>
                                    )}
                                    {new Date(contract.updated_at)
                                      .getDate()
                                      .toString()
                                      .padStart(2, '0')}
                                    /
                                    {(
                                      new Date(contract.updated_at).getMonth() +
                                      1
                                    )
                                      .toString()
                                      .padStart(2, '0')}
                                    /
                                    {new Date(
                                      contract.updated_at
                                    ).getFullYear()}
                                  </span>
                                )}
                              </td>
                            )}
                            {!readOnly && (
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setSelectedContract(contract);
                                    setShowDelete(true);
                                  }}
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                  fill="rgba(255,0,0,1)"
                                >
                                  <path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"></path>
                                </svg>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
            {!readOnly && (
              <Col xs={12} className="mt-3">
                <ExportContracts
                  objects={contracts}
                  selectedSalon={selectedSalon}
                  selectedCaa={selectedCaa}
                  selectedRfa={selectedRfa}
                  selectedYears={selectedYears}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      {!readOnly && (
        <Card className="mt-3" id="emails">
          <Card.Body className="">
            <Row>
              <Col xs={12} className="mt-3">
                <EmailLogsContractsTable />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {!readOnly && (
        <Offcanvas
          show={showDelete && selectedContract.id !== undefined}
          onHide={() => {
            setShowDelete(false);
            setSelectedContract({});
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              La suppression de la ligne est définitive.
              <br />
              Voulez-vous continuer ?
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Button
              size="sm"
              variant="falcon-danger"
              className="me-2"
              onClick={() => {
                deleteContract();
                setShowDelete(false);
                setSelectedContract({});
              }}
            >
              Oui, supprimer
            </Button>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowDelete(false);
              }}
            >
              Annuler
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

IndexContracts.propTypes = {
  readOnly: PropTypes.bool
};

export default IndexContracts;
