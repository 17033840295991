import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import * as Moment from 'moment';
import {
  IndexAdmin,
  PostCompany,
  PutCompany,
  ShowCompanyAdmin
} from '../../../../services/CompanyService';
import { IndexCompanyMemberType } from '../../../../services/CompanyMemberTypeService';
import { IndexDepartment } from '../../../../services/DepartmentService';
import { IndexTrade } from '../../../../services/TradeService';
import { IndexCeeCountries } from '../../../../services/CeeCountryService';
import { IndexFrenchStates } from '../../../../services/FrenchStateService';
import { IndexUserAdminSortByLastname } from '../../../../services/UserService';
import BackModalConfirm from '../../commons/BackModalConfirm';
import FormAlert from '../../commons/FormAlert';
import FileUploader from '../../commons/fileUploader';
import ManageRepresentative from '../../authpages/admins/companies/manageRepresentative';
import { IndexEnvironmentalPolicy } from '../../../../services/EnvironmentalPolicyService';
import NavbarStandard from '../../commons/NavbarStandard';
import logoTredUnion from '../../../../assets/img/tredunion/logo-TRED-UNION.png';
import Section from '../../../common/Section';

const FillRequestManage = () => {
  const { uuid } = useParams();
  const [trigger] = useState(Date.now());
  const [companies, setCompanies] = useState([]);
  const [id, setId] = useState('');
  const [departments, setDepartments] = useState([]);
  const [trades, setTrades] = useState([]);
  const [ceeCountries, setCeeCountries] = useState([]);
  const [frenchStates, setFrenchStates] = useState([]);
  const [environmentalPolicies, setEnvironmentalPolicies] = useState([]);
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const [formDataEdited, setFormDataEdited] = useState(false);
  const [usersSelect, setUsersSelect] = useState([]);
  const [companyMemberTypes, setCompanyMemberTypes] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    siren: '',
    siret: '',
    company_member_types: [],
    new_company_member_type: '',
    description: '',
    headquarter_id: '',
    headquarter_type: '',
    address: '',
    zip_code: '',
    city: '',
    department_id: '',
    country_id: 1,
    year_of_creation: '',
    contact_website: '',
    contact_phone: '',
    contact_email: '',
    logo_url: '',
    video_url: '',
    total_workforce: 0,
    total_workforce_including_drivers: 0,
    count_registration_documents: '',
    count_vehicles: '',
    contact_label: '',
    contact_firstname: '',
    contact_lastname: '',
    contact_occupation: '',
    contact_profile_picture_url: '',
    contact__phone: '',
    contact__mobile_phone: '',
    contact__email: '',
    manager_firstname: '',
    manager_lastname: '',
    manager_profile_picture_url: '',
    manager_phone: '',
    manager_mobile_phone: '',
    manager_email: '',
    operator_firstname: '',
    operator_lastname: '',
    operator_profile_picture_url: '',
    operator_phone: '',
    operator_mobile_phone: '',
    operator_email: '',
    sales_manager_firstname: '',
    sales_manager_lastname: '',
    sales_manager_profile_picture_url: '',
    sales_manager_phone: '',
    sales_manager_mobile_phone: '',
    sales_manager_email: '',
    other_label: '',
    other_firstname: '',
    other_lastname: '',
    other_profile_picture_url: '',
    other_phone: '',
    other_mobile_phone: '',
    other_email: '',
    trades: [],
    cee_countries: [],
    french_states: [],
    environmental_policies: [],
    hide_public: 0,

    tracteurs: 0,
    porteurs: 0,
    semiremorques: 0,
    remorques: 0,
    bennecerealiere: 0,
    benneferraille: 0,
    bennetp: 0,
    citerne: 0,
    fma: 0,
    fourgon: 0,
    frigo: 0,
    plateau: 0,
    porteengins: 0,
    portevoiture: 0,
    tautliner: 0,
    vul: 0,

    carbon_balance_available: 0,
    carbon_balance_date: '',
    co2_per_km: '',

    company_representative_sections: [],

    instagram_url: '',
    facebook_url: '',
    linkedin_url: '',
    tiktok_url: ''
  });

  let navigate = useNavigate();

  useEffect(() => {
    const fetchCompany = async () => {
      const responseCompany = await ShowCompanyAdmin(`${uuid}?uuid=${uuid}`);
      if (responseCompany.success === true) {
        setId(responseCompany.data.id);
        let dataCompany = responseCompany.data;
        setCompany(dataCompany);
        let memberTypesArray = [];
        dataCompany.member_types.map(type => {
          memberTypesArray.push({
            value: type.id,
            label: type.name
          });
        });
        let tradesArray = [];
        dataCompany.trades.map(trade => {
          tradesArray.push({
            value: `${trade.id}`,
            label: trade.name
          });
        });
        let ceeCountriesArray = [];
        dataCompany.cee_countries.map(country => {
          ceeCountriesArray.push({
            value: `${country.id}`,
            label: country.name
          });
        });
        let frenchStatesArray = [];
        dataCompany.french_states.map(state => {
          frenchStatesArray.push({
            value: `${state.id}`,
            label: state.name
          });
        });
        let environmentPoliciesArray = [];
        dataCompany.environmental_policies.map(policy => {
          if (policy.pivot) {
            environmentPoliciesArray.push({
              id: policy.pivot.environmental_policy_id,
              name: policy.name,
              date: policy.pivot.validity_date
                ? Moment(policy.pivot.validity_date)
                    .local()
                    .format('YYYY-MM-DD')
                : ''
            });
          }
        });
        let companyRepresentativeSectionsArray = [];
        dataCompany.company_representative_sections.map(section => {
          companyRepresentativeSectionsArray.push({
            id: section.id,
            name: section.name,
            type: section.type?.id,
            order: section.order,
            users: section.users.map(user => ({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`
            }))
          });
        });
        setFormData({
          name: dataCompany.name,
          siren: dataCompany.siren,
          siret: dataCompany.siret,
          description: dataCompany.description,
          headquarter_id:
            dataCompany.headquarter_id !== null &&
            dataCompany.headquarter_id !== undefined
              ? dataCompany.headquarter_id
              : '',
          headquarter_type:
            dataCompany.headquarter_id !== null &&
            dataCompany.headquarter_type !== null &&
            dataCompany.headquarter_type !== undefined
              ? dataCompany.headquarter_type
              : '',
          address: dataCompany.address,
          zip_code: dataCompany.zip_code,
          city: dataCompany.city,
          department_id: dataCompany.department_id,
          country_id: dataCompany.country_id,
          year_of_creation: dataCompany.year_of_creation,
          company_member_types: memberTypesArray,
          contact_website: dataCompany.contact_website,
          contact_phone: dataCompany.contact_phone,
          contact_email: dataCompany.contact_email,
          logo_url: dataCompany.logo_url,
          video_url: dataCompany.video_url,
          total_workforce: dataCompany.total_workforce,
          total_workforce_including_drivers:
            dataCompany.total_workforce_including_drivers,
          count_registration_documents:
            dataCompany.count_registration_documents,
          count_vehicles: dataCompany.count_vehicles,
          contact_id: dataCompany.contact_id,
          contact_label: dataCompany.contact_label,
          contact_firstname: dataCompany.contact_firstname,
          contact_lastname: dataCompany.contact_lastname,
          contact_occupation: dataCompany.contact_occupation,
          contact_profile_picture_url: dataCompany.contact_profile_picture_url,
          contact__phone: dataCompany.contact__phone,
          contact__mobile_phone: dataCompany.contact__mobile_phone,
          contact__email: dataCompany.contact__email,
          manager_id: dataCompany.manager_id,
          manager_firstname: dataCompany.manager_firstname,
          manager_lastname: dataCompany.manager_lastname,
          manager_profile_picture_url: dataCompany.manager_profile_picture_url,
          manager_phone: dataCompany.manager_phone,
          manager_mobile_phone: dataCompany.manager_mobile_phone,
          manager_email: dataCompany.manager_email,
          operator_id: dataCompany.operator_id,
          operator_firstname: dataCompany.operator_firstname,
          operator_lastname: dataCompany.operator_lastname,
          operator_profile_picture_url:
            dataCompany.operator_profile_picture_url,
          operator_phone: dataCompany.operator_phone,
          operator_mobile_phone: dataCompany.operator_mobile_phone,
          operator_email: dataCompany.operator_email,
          sales_manager_id: dataCompany.sales_manager_id,
          sales_manager_firstname: dataCompany.sales_manager_firstname,
          sales_manager_lastname: dataCompany.sales_manager_lastname,
          sales_manager_profile_picture_url:
            dataCompany.sales_manager_profile_picture_url,
          sales_manager_phone: dataCompany.sales_manager_phone,
          sales_manager_mobile_phone: dataCompany.sales_manager_mobile_phone,
          sales_manager_email: dataCompany.sales_manager_email,
          other_id: dataCompany.other_id,
          other_label: dataCompany.other_label,
          other_firstname: dataCompany.other_firstname,
          other_lastname: dataCompany.other_lastname,
          other_profile_picture_url: dataCompany.other_profile_picture_url,
          other_phone: dataCompany.other_phone,
          other_mobile_phone: dataCompany.other_mobile_phone,
          other_email: dataCompany.other_email,
          trades: tradesArray,
          cee_countries: ceeCountriesArray,
          french_states: frenchStatesArray,
          environmental_policies: environmentPoliciesArray,
          hide_public: dataCompany.hide_public,

          tracteurs: dataCompany.tracteurs,
          porteurs: dataCompany.porteurs,
          semiremorques: dataCompany.semiremorques,
          remorques: dataCompany.remorques,
          bennecerealiere: dataCompany.bennecerealiere,
          benneferraille: dataCompany.benneferraille,
          bennetp: dataCompany.bennetp,
          citerne: dataCompany.citerne,
          fma: dataCompany.fma,
          fourgon: dataCompany.fourgon,
          frigo: dataCompany.frigo,
          plateau: dataCompany.plateau,
          porteengins: dataCompany.porteengins,
          portevoiture: dataCompany.portevoiture,
          tautliner: dataCompany.tautliner,
          vul: dataCompany.vul,

          carbon_balance_available: dataCompany.carbon_balance_available,
          carbon_balance_date: dataCompany.carbon_balance_date
            ? Moment(dataCompany.carbon_balance_date)
                .local()
                .format('YYYY-MM-DD')
            : '',
          co2_per_km: dataCompany.co2_per_km,

          company_representative_sections: companyRepresentativeSectionsArray,

          instagram_url: dataCompany.instagram_url,
          facebook_url: dataCompany.facebook_url,
          linkedin_url: dataCompany.linkedin_url,
          tiktok_url: dataCompany.tiktok_url
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanyMemberTypes = async () => {
      const response = await IndexCompanyMemberType();
      if (response.success === true) {
        let companyMemberTypesArray = [];
        response.data.map(type => {
          companyMemberTypesArray.push({
            value: type.id,
            label: type.name
          });
        });
        setCompanyMemberTypes(companyMemberTypesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchDepartments = async () => {
      const responseDepartments = await IndexDepartment();
      if (responseDepartments.success === true) {
        setDepartments(responseDepartments.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchTrades = async () => {
      const responseTrades = await IndexTrade();
      if (responseTrades.success === true) {
        let tradesArray = [];
        responseTrades.data.map(trade => {
          tradesArray.push({
            value: `${trade.id}`,
            label: trade.name
          });
        });
        setTrades(tradesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCeeCountries = async () => {
      const response = await IndexCeeCountries();
      if (response.success === true) {
        let countriesArray = [];
        response.data
          .sort((a, b) =>
            a.name.localeCompare(b.name, 'fr', { ignorePunctuation: true })
          )
          .map(country => {
            countriesArray.push({
              value: `${country.id}`,
              label: country.name
            });
          });
        setCeeCountries(countriesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchFrenchStates = async () => {
      const response = await IndexFrenchStates();
      if (response.success === true) {
        let statesArray = [];
        response.data
          .sort((a, b) =>
            a.name.localeCompare(b.name, 'fr', { ignorePunctuation: true })
          )
          .map(state => {
            statesArray.push({
              value: `${state.id}`,
              label: state.name
            });
          });
        setFrenchStates(statesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchEnvironmentalPolicies = async () => {
      const response = await IndexEnvironmentalPolicy();
      if (response.success === true) {
        setEnvironmentalPolicies(response.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUsers = async () => {
      const responseObjects = await IndexUserAdminSortByLastname();
      if (responseObjects.success === true) {
        let usersArray = [];
        responseObjects.data
          .filter(u => u.lastname && u.firstname)
          .map(user => {
            usersArray.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`
            });
          });
        setUsersSelect(usersArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    fetchCompanyMemberTypes();
    fetchDepartments();
    fetchUsers();
    fetchTrades();
    fetchCeeCountries();
    fetchFrenchStates();
    fetchEnvironmentalPolicies();
    if (uuid) {
      fetchCompany();
    }
  }, [trigger]);

  const handleFieldChange = e => {
    setFormDataEdited(true);
    if (e.target.type === 'number') {
      if (parseInt(e.target.value) < 0) {
        e.target.value = 0;
      } else if (parseInt(e.target.value) > 1000) {
        e.target.value = 1000;
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutCompany(company.id, formData);
    } else {
      response = await PostCompany(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Vos informations ont été enregistrées, vous pouvez fermer cette page.`;
      toast(successMessage);
      setSuccessForm(successMessage);
      if (!id) {
        setIsLoading(true);
        setTimeout(function () {
          //window.location.href = `/administration/entreprises/${response.data.id}/modifier`;
        }, 2500);
      } else {
        setTimeout(function () {
          //window.location.reload();
        }, 2000);
      }
    }
  };

  return (
    <>
      <NavbarStandard />
      <Section bg="light" className="d-flex align-items-end p-1 mt-7 pt-5">
        <Row className="justify-content-center share-file-page">
          <Col xs={12} md={12} className="mt-4 mb-0 text-center">
            <img src={logoTredUnion} className="logo-tred-union" />
            <h3 className="admin-title mt-4 mb-5">
              Mettre à jour les informations de ma société - {company.name}
            </h3>
          </Col>
          <Col xs={12} md={12} className="mt-0 mb-4">
            {(uuid && company.id !== null && company.id !== undefined) ||
            !uuid ? (
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col xs={12}>
                    {errorForm && (
                      <div className="mt-3">
                        <FormAlert variant="danger" data={errorForm} />
                      </div>
                    )}
                    {successForm && (
                      <div className="mt-3">
                        <FormAlert
                          className="mt-3"
                          variant="success"
                          data={successForm}
                        />
                      </div>
                    )}
                  </Col>
                  <Col lg={12}>
                    <Card className="mb-3 pb-3">
                      <Card.Body className="bg-light">
                        <Row className="gx-2 gy-3">
                          <Col xs={6}>
                            <Form.Group>
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '4px',
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Logo :
                              </Form.Label>
                              <FileUploader
                                pathToUpload="companies/logos"
                                formData={formData}
                                setFormData={setFormData}
                                format="img"
                                databaseField="logo_url"
                                cropperEnabled={false}
                                currentImage={company.logo_url}
                              />
                            </Form.Group>
                          </Col>
                          {/*<Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>*/}
                          <Col md="12">
                            {/*<Form.Group controlId="name">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '4px',
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Nom :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Nom de la société (exemple : ALTRANS)"
                                value={formData.name || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>*/}
                            {/*<Form.Check
                              className="mt-3"
                              type="checkbox"
                              label="Cacher au public "
                              onChange={e => {
                                if (e.target.checked) {
                                  setFormData({
                                    ...formData,
                                    hide_public: 1
                                  });
                                } else {
                                  setFormData({
                                    ...formData,
                                    hide_public: 0
                                  });
                                }
                              }}
                              defaultChecked={company && company.hide_public}
                            />*/}
                          </Col>
                          {/*<Col md="12" className="pb-2 pt-0">
                            <div className="border-dashed-bottom"></div>
                          </Col>*/}
                          {/*<Col md="12">
                            <Form.Group controlId="headquarter_type">
                              <Form.Label>Type :</Form.Label>
                              <Form.Select
                                aria-label="Filiale de"
                                name="headquarter_type"
                                value={formData.headquarter_type}
                                onChange={handleFieldChange}
                              >
                                <option value={''}>Siège</option>
                                <option value={'filiale'}>Filiale</option>
                                <option value={'agence'}>Agence</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>*/}
                          {/*{formData.headquarter_type !== '' && (
                            <Col md="12">
                              <Form.Group controlId="headquarter_id">
                                <Form.Label>
                                  <span style={{ textTransform: 'capitalize' }}>
                                    {formData.headquarter_type}
                                  </span>{' '}
                                  de :
                                </Form.Label>
                                <Form.Select
                                  aria-label="Filiale de"
                                  name="headquarter_id"
                                  value={formData.headquarter_id}
                                  onChange={handleFieldChange}
                                >
                                  <option value={''}>
                                    {formData.headquarter_type === 'filiale'
                                      ? 'Sélectionner la société mère'
                                      : 'Sélectionner la société'}
                                  </option>
                                  {companies.map((c, index) => (
                                    <option value={c.id} key={index}>
                                      {c.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          )}*/}
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="year_of_creation">
                              <Form.Label>Année de création :</Form.Label>
                              <Form.Control
                                type="text"
                                name="year_of_creation"
                                placeholder="Année de création (exemple : 1990)"
                                value={formData.year_of_creation || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="address">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '4px',
                                    marginBottom: '4px'
                                  }}
                                >
                                  <path
                                    d="M12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9l4.95-4.95zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Adresse :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address"
                                placeholder="Adresse de la société"
                                value={formData.address || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="department_id">
                              <Form.Label>Département :</Form.Label>
                              <Form.Select
                                aria-label="Pays"
                                name="department_id"
                                value={formData.department_id}
                                onChange={handleFieldChange}
                              >
                                {departments.map((c, index) => (
                                  <option value={c.id} key={index}>
                                    {c.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="zip_code">
                              <Form.Label>Code postal :</Form.Label>
                              <Form.Control
                                type="text"
                                name="zip_code"
                                placeholder="Code postal de la société"
                                value={formData.zip_code || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="city">
                              <Form.Label>Ville :</Form.Label>
                              <Form.Control
                                type="text"
                                name="city"
                                placeholder="Ville de la société"
                                value={formData.city || ''}
                                onChange={handleFieldChange}
                                style={{ textTransform: 'uppercase' }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="video_url">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '0px',
                                    marginBottom: '3px'
                                  }}
                                >
                                  <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>{' '}
                                Vidéo :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="video_url"
                                placeholder="Exemple : https://youtube.com/embed/mI-Zq2IFEWM"
                                value={formData.video_url || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="contact_website">
                              <Form.Label>Site internet :</Form.Label>
                              <Form.Control
                                type="text"
                                name="contact_website"
                                placeholder="Site internet de la société"
                                value={formData.contact_website || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="contact_phone">
                              <Form.Label>Numéro de téléphone :</Form.Label>
                              <Form.Control
                                type="text"
                                name="contact_phone"
                                placeholder="Numéro de téléphone de la société"
                                value={formData.contact_phone || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="12">
                            <Form.Group controlId="contact_phone">
                              <Form.Label>Email générique :</Form.Label>
                              <Form.Control
                                type="text"
                                name="contact_email"
                                placeholder="Adresse email de la société"
                                value={formData.contact_email || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="siren">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '4px',
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                SIREN :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="siren"
                                placeholder="SIREN société"
                                value={formData.siren || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="siren">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '4px',
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                SIRET :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="siret"
                                placeholder="SIRET société"
                                value={formData.siret || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="company_member_types">
                              <Form.Label>Membre de :</Form.Label>
                              <Select
                                closeMenuOnSelect={false}
                                options={companyMemberTypes}
                                placeholder="Choisir un ou plusieurs types..."
                                isMulti
                                name="company_member_types"
                                classNamePrefix="react-select"
                                value={formData.company_member_types}
                                onChange={value => {
                                  setFormDataEdited(true);
                                  setFormData({
                                    ...formData,
                                    company_member_types: value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/*{formData.company_member_type_id === 'new' && (
                            <Col md="12">
                              <Form.Group controlId="siren">
                                <Form.Label>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="17"
                                    height="17"
                                    style={{
                                      marginRight: '4px',
                                      marginBottom: '2px'
                                    }}
                                  >
                                    <path
                                      d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                                      fill="rgba(100,99,99,1)"
                                    />
                                  </svg>
                                  Nouveau type de membre à enregistrer :
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="new_company_member_type"
                                  placeholder=""
                                  value={formData.new_company_member_type || ''}
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                            </Col>
                          )}*/}
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <Col md="6">
                            <Form.Group controlId="total_workforce">
                              <Form.Label>Total effectifs :</Form.Label>
                              <Form.Control
                                type="number"
                                onWheel={e => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                name="total_workforce"
                                placeholder="Total effectifs"
                                value={formData.total_workforce || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group controlId="total_workforce_including_drivers">
                              <Form.Label>Dont conducteurs :</Form.Label>
                              <Form.Control
                                type="number"
                                onWheel={e => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                name="total_workforce_including_drivers"
                                placeholder="Dont conducteurs"
                                value={
                                  formData.total_workforce_including_drivers ||
                                  ''
                                }
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <Col md="12">
                            <Form.Group controlId="headquarter_id">
                              <Form.Label>Métier(s) :</Form.Label>
                              <Select
                                closeMenuOnSelect={false}
                                options={trades}
                                placeholder="Choisir un ou plusieurs métiers..."
                                isMulti
                                name="trades"
                                classNamePrefix="react-select"
                                value={formData.trades}
                                onChange={value => {
                                  setFormDataEdited(true);
                                  setFormData({
                                    ...formData,
                                    trades: value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <Col md="12">
                            <Form.Group controlId="served_countries">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '5px',
                                    marginBottom: '3px'
                                  }}
                                >
                                  <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm4.5-12.5L14 14l-6.5 2.5L10 10l6.5-2.5zM12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Pays desservis :
                              </Form.Label>
                              <Select
                                closeMenuOnSelect={false}
                                options={ceeCountries}
                                placeholder="Choisir un ou plusieurs pays..."
                                isMulti
                                name="cee_countries"
                                classNamePrefix="react-select"
                                value={formData.cee_countries}
                                onChange={value => {
                                  setFormDataEdited(true);
                                  setFormData({
                                    ...formData,
                                    cee_countries: value
                                  });
                                }}
                              />
                              {/*<Form.Control
                          as="textarea"
                          rows={3}
                          name="served_countries"
                          value={formData.served_countries || ''}
                          onChange={handleFieldChange}
                        />*/}
                            </Form.Group>
                          </Col>
                          {/*<Col md="12">
                      <Form.Group controlId="served_states">
                        <Form.Label>Régions desservies :</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="served_states"
                          value={formData.served_states || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>*/}

                          <Col md="12">
                            <Form.Group controlId="served_countries">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '5px',
                                    marginBottom: '3px'
                                  }}
                                >
                                  <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm4.5-12.5L14 14l-6.5 2.5L10 10l6.5-2.5zM12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Départements français desservis :
                              </Form.Label>
                              <Select
                                closeMenuOnSelect={false}
                                options={frenchStates}
                                placeholder="Choisir un ou plusieurs départements..."
                                isMulti
                                name="cee_countries"
                                classNamePrefix="react-select"
                                value={formData.french_states}
                                onChange={value => {
                                  setFormDataEdited(true);
                                  setFormData({
                                    ...formData,
                                    french_states: value
                                  });
                                }}
                              />
                              {/*<Form.Control
                          as="textarea"
                          rows={3}
                          name="served_countries"
                          value={formData.served_countries || ''}
                          onChange={handleFieldChange}
                        />*/}
                            </Form.Group>
                          </Col>

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <Col md="12">
                            <Form.Group controlId="description">
                              <Form.Label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{
                                    marginRight: '5px',
                                    marginBottom: '3px'
                                  }}
                                >
                                  <path
                                    d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
                                    fill="rgba(100,99,99,1)"
                                  />
                                </svg>
                                Activités :
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                value={formData.description || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <h5 style={{ fontWeight: 900 }}>Matériel</h5>
                          <h6 style={{ fontWeight: 900 }} className="mb-0">
                            Véhicules moteurs :
                          </h6>
                          <Col md="12">
                            <table className="table table-responsive">
                              <tr>
                                <td style={{ width: '20%' }}>Tracteurs :</td>
                                <td style={{ width: '40%' }} className="p-0">
                                  <Form.Control
                                    type="number"
                                    onWheel={e => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    name="tracteurs"
                                    placeholder=""
                                    className="p-1"
                                    value={formData.tracteurs || ''}
                                    onChange={handleFieldChange}
                                  />
                                </td>
                                <td style={{ width: '40%' }}></td>
                              </tr>
                              <tr>
                                <td>Porteurs :</td>
                                <td className="p-0">
                                  <Form.Control
                                    type="number"
                                    onWheel={e => {
                                      e.preventDefault();
                                      e.target.blur();
                                    }}
                                    name="porteurs"
                                    placeholder=""
                                    className="p-1"
                                    value={formData.porteurs || ''}
                                    onChange={handleFieldChange}
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Total</b>
                                </td>
                                <td className="p-0">
                                  <Form.Control
                                    type="number"
                                    disabled={true}
                                    placeholder=""
                                    className="p-1"
                                    value={
                                      (formData.tracteurs
                                        ? parseInt(formData.tracteurs)
                                        : 0) +
                                      (formData.porteurs
                                        ? parseInt(formData.porteurs)
                                        : 0)
                                    }
                                  />
                                </td>
                                <td></td>
                              </tr>
                            </table>
                          </Col>
                          {/*<Col md="6">
                      <Form.Group controlId="equipments">
                        <Form.Label>Type de matériel :</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="equipments"
                          value={formData.equipments || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>*/}

                          <h6 style={{ fontWeight: 900 }} className="mb-0">
                            Véhicules non-moteurs :
                          </h6>

                          <table className="table table-responsive">
                            <tr>
                              <td style={{ width: '25%' }}>Semi-remorques :</td>
                              <td style={{ width: '35%' }} className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="semiremorques"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.semiremorques || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td style={{ width: '40%' }}></td>
                            </tr>
                            <tr>
                              <td>Remorques :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="remorques"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.remorques || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  disabled={true}
                                  placeholder=""
                                  className="p-1"
                                  value={
                                    (formData.semiremorques
                                      ? parseInt(formData.semiremorques)
                                      : 0) +
                                    (formData.remorques
                                      ? parseInt(formData.remorques)
                                      : 0)
                                  }
                                />
                              </td>
                              <td></td>
                            </tr>
                          </table>

                          <h6 style={{ fontWeight: 900 }} className="mb-0">
                            Type de matériel non moteur :
                          </h6>

                          <table className="table table-responsive">
                            <tr>
                              <td style={{ width: '25%' }}>
                                Benne Céréalière :
                              </td>
                              <td style={{ width: '35%' }} className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="bennecerealiere"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.bennecerealiere || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td style={{ width: '40%' }}></td>
                            </tr>
                            <tr>
                              <td>Benne Ferraille :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="benneferraille"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.benneferraille || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Benne TP :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="bennetp"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.bennetp || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Citerne :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="citerne"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.citerne || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>FMA :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="fma"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.fma || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Fourgon :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="fourgon"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.fourgon || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Frigo :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="frigo"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.frigo || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Plateau :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="plateau"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.plateau || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Porte-engins :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="porteengins"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.porteengins || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Porte voiture :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="portevoiture"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.portevoiture || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Tautliner :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="tautliner"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.tautliner || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>VUL :</td>
                              <td className="p-0">
                                <Form.Control
                                  type="number"
                                  min={0}
                                  max={1000}
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="vul"
                                  placeholder=""
                                  className="p-1"
                                  value={formData.vul || ''}
                                  onChange={handleFieldChange}
                                />
                              </td>
                              <td></td>
                            </tr>
                          </table>

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <h5 style={{ fontWeight: 900 }} className="mb-3">
                            Démarches environnementales
                          </h5>

                          {environmentalPolicies.map((c, index) => (
                            <Col md="12" className="mt-0" key={index}>
                              <Form.Group
                                controlId={`environmental_policy_${c.id}`}
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={c.name}
                                  name={`environmental_policy_${c.id}`}
                                  checked={formData.environmental_policies.find(
                                    x => x.id === c.id
                                  )}
                                  onChange={e => {
                                    setFormDataEdited(true);
                                    if (e.target.checked) {
                                      setFormData({
                                        ...formData,
                                        environmental_policies: [
                                          ...formData.environmental_policies,
                                          { id: c.id, name: c.name }
                                        ]
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        environmental_policies:
                                          formData.environmental_policies.filter(
                                            x => x.id !== c.id
                                          )
                                      });
                                    }
                                  }}
                                />
                                {formData.environmental_policies.find(
                                  x => x.id === c.id
                                ) && (
                                  <Fragment>
                                    Date de validité :
                                    <Form.Control
                                      type="date"
                                      className="mb-3"
                                      name={`environmental_policy_${c.id}_date`}
                                      value={
                                        formData.environmental_policies.find(
                                          x => x.id === c.id
                                        )?.date || ''
                                      }
                                      onChange={e => {
                                        setFormDataEdited(true);
                                        setFormData({
                                          ...formData,
                                          environmental_policies:
                                            formData.environmental_policies.map(
                                              x => {
                                                if (x.id === c.id) {
                                                  x.date = e.target.value;
                                                }
                                                return x;
                                              }
                                            )
                                        });
                                      }}
                                    />
                                  </Fragment>
                                )}
                              </Form.Group>
                            </Col>
                          ))}

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <h5 style={{ fontWeight: 900 }} className="mb-3">
                            Données carbone
                          </h5>

                          <Col md="12" className="mt-0">
                            <Form.Group controlId="carbon_balance_available">
                              <Form.Check
                                type="checkbox"
                                label="Bilan carbone disponible ?"
                                name="carbon_balance_available"
                                checked={formData.carbon_balance_available}
                                onChange={e => {
                                  setFormDataEdited(true);
                                  setFormData({
                                    ...formData,
                                    carbon_balance_available: e.target.checked
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {(formData.carbon_balance_available === true ||
                            formData.carbon_balance_available === 1) && (
                            <Col md="12" className="mt-0">
                              <Form.Group controlId="carbon_balance_date">
                                <Form.Label>Date du bilan carbone :</Form.Label>
                                <Form.Control
                                  type="date"
                                  className="mb-3"
                                  name="carbon_balance_date"
                                  value={formData.carbon_balance_date || ''}
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col md="12" className="mt-0">
                            <Form.Group controlId="co2_per_km">
                              <Form.Label>
                                g Co2 / T / km transporté :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="co2_per_km"
                                placeholder="g Co2 / T / km transporté"
                                value={formData.co2_per_km || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>

                          <h5 style={{ fontWeight: 900 }} className="mb-3">
                            Réseaux sociaux
                          </h5>

                          <Col md="6">
                            <Form.Group controlId="facebook">
                              <Form.Label>Lien page facebook :</Form.Label>
                              <Form.Control
                                type="text"
                                name="facebook_url"
                                placeholder="Lien de la page Facebook"
                                value={formData.facebook_url || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="instagram_url">
                              <Form.Label>Instagram :</Form.Label>
                              <Form.Control
                                type="text"
                                name="instagram_url"
                                placeholder="Lien Instagram"
                                value={formData.instagram_url || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="linkedin_url">
                              <Form.Label>Linkedin :</Form.Label>
                              <Form.Control
                                type="text"
                                name="linkedin_url"
                                placeholder="Lien Linkedin"
                                value={formData.linkedin_url || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="tiktok_url">
                              <Form.Label>TikTok :</Form.Label>
                              <Form.Control
                                type="text"
                                name="tiktok_url"
                                placeholder="Lien TikTok"
                                value={formData.tiktok_url || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/*<Col lg={4}>
                    <div className="sticky-sidebar">
                      <Card>
                        <Row>
                          <Col xs={12} className="p-3 ps-4">
                            <h4 style={{ fontWeight: 900 }} className="mb-3">
                              Fonctions
                            </h4>
                            <ManageRepresentative
                              formData={formData}
                              setFormData={setFormData}
                              usersSelect={usersSelect}
                              setFormDataEdited={setFormDataEdited}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>*/}
                  <Col xs={12}>
                    <Card>
                      <Card.Body>
                        <Row className="flex-between-center">
                          <Col xs="auto">
                            {/*<Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                window.location.href =
                                  '/administration/entreprises';
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                style={{ marginBottom: '2px' }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                              </svg>
                              {!id ? 'Annuler' : 'Retour'}
                            </Button>*/}
                            {/*{id && (
                              <>
                                <Button
                                  size="sm"
                                  variant="falcon-default"
                                  className="me-2"
                                  onClick={() => {
                                    window.open(
                                      `/adherents/${id}`,
                                      '_blank',
                                      'noopener,noreferrer'
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    style={{
                                      marginBottom: '2px',
                                      marginRight: '4px'
                                    }}
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                  </svg>
                                  Voir
                                </Button>
                                <Button
                                  size="sm"
                                  variant="falcon-default"
                                  className="me-2"
                                  onClick={() => {
                                    navigate(
                                      `/administration/factures/ajouter?company=${id}`
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    style={{
                                      marginBottom: '2px',
                                      marginRight: '4px'
                                    }}
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9zM8 7h3v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z" />
                                  </svg>
                                  Facturer
                                </Button>
                              </>
                            )}*/}
                            <Button
                              size="sm"
                              variant="primary"
                              className="me-2 admin-primary"
                              type="submit"
                              disabled={isLoading}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '4px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                  fill="rgba(255,255,255,1)"
                                />
                              </svg>
                              Enregistrer
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Form>
            ) : (
              <p>
                <b>Chargement en cours...</b>
              </p>
            )}
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default FillRequestManage;
